import { useState, useEffect } from "react";

const usePreventReload  = () => {
    const [isDirty, setDirty] = useState(false);
    const message = "There are unsaved changes. Are you sure that you want to leave?"
    useEffect(() => {
        //Detecting browser closing
        window.onbeforeunload = isDirty && (() => message);

        return () => {
            window.onbeforeunload = null;
        };
    }, [isDirty]);

    return [isDirty, () => setDirty(true), () => setDirty(false)];
};

export default usePreventReload;