import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import Button from '@material-ui/core/Button';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import usePreventReload from '../../../hooks/usePreventReload';
import './SummaryLimopsConfig.css';
import { Prompt } from 'react-router-dom';
import GenericSnackbar from '../../GenericSnackbar/GenericSnackbar';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import { SUMMARY_LIMMOPS_GROUP, SUMMARY_LIMMOPS_SUBFLOTA } from '../../../utils/Constants';
import SummaryLimopsMaintainer from './SummaryLimopsMaintainer/SummaryLimopsMaintainer';
import SummaryLimopsMaintainerGroup from './SummaryLimopsMaintainerGroup/SummaryLimopsMaintainerGroup';
let disabledFlota = true;
let disabledGroup = true;

function SummaryLimopsConfig(props) {
    const [isDirty, setDirty, setClean] = usePreventReload();
    const [state, setState] = React.useState(props.configurationData ? props.configurationData : {
        operatorGroupFilter: SUMMARY_LIMMOPS_GROUP,
        operatorSubFlotaFilter: SUMMARY_LIMMOPS_SUBFLOTA,
        filter: "subflotaOwner",
        lastModDate: ''
    });

    if (state.filter) {
        disabledFlota = state.filter === "operatorType" ? true : false;
        disabledGroup = state.filter === "subflotaOwner" ? true : false;
    }


    const handleSelectChange = (event) => {
        setDirty();
        let index;
        let filters = event.target.value;

        if (filters.includes('OPER')) {
            if (isAllSelected('OPER')) {
                SUMMARY_LIMMOPS_GROUP.forEach(a => {
                    index = filters.indexOf(a);
                    filters.splice(index, 1);
                })
            } else {
                SUMMARY_LIMMOPS_GROUP.forEach(a => {
                    index = filters.indexOf(a);
                    if (index === -1) {
                        filters.push(a);
                    }
                })
            }
            index = filters.indexOf('OPER');
            filters.splice(index, 1);
        }



        setState({ ...state, operatorGroupFilter: filters });
    }
    const handleSelectChangeSubFlota = (event) => {
        setDirty();
        let index;
        let filters = event.target.value;
        if (filters.includes('SUBFLOTA')) {
            if (isAllSelected2('SUBFLOTA')) {
                SUMMARY_LIMMOPS_SUBFLOTA.forEach(a => {
                    index = filters.indexOf(a);
                    filters.splice(index, 1);
                })
            } else {
                SUMMARY_LIMMOPS_SUBFLOTA.forEach(a => {
                    index = filters.indexOf(a);
                    if (index === -1) {
                        filters.push(a);
                    }
                })
            }
            index = filters.indexOf('SUBFLOTA');
            filters.splice(index, 1);
        }


        setState({ ...state, operatorSubFlotaFilter: filters });
    }
    const handleSelectChangeRadioButtom = (event) => {
        setDirty();
        disabledFlota = event.target.value === "operatorType" ? true : false;
        disabledGroup = event.target.value === "subflotaOwner" ? true : false;
        setState({ ...state, filter: event.target.value });

    }

    const [snackbar, setSnackbar] = React.useState('');

    const saveChanges = () => {

        const modDate = new Date();
        state.lastModDate = modDate.toUTCString();
        props.updateConfigurationData('summaryLimopsConfig', state).then(() => {
            setSnackbar({ msg: "Succesfully saved changes!", date: new Date(), severity: "success" });
            setState({ ...state, lastModDate: modDate, });
            setClean();
        }, error => {
            console.error(error);
            setSnackbar({ msg: "Error saving changes. Try again later", date: new Date(), severity: "error" });
        });
    }


    const isAllSelected = (oper) => {
        let isAllSelected = true;
        if (oper === 'OPER') {
            SUMMARY_LIMMOPS_GROUP.forEach(a => {
                if (state.operatorGroupFilter.indexOf(a) === -1) {
                    isAllSelected = false;
                }
            })
        } else {
            SUMMARY_LIMMOPS_GROUP.forEach(a => {
                if (state.operatorGroupFilter.indexOf(a) === -1) {
                    isAllSelected = false;
                }
            })
        }
        if (oper === 'SUBFLOTA') {
            SUMMARY_LIMMOPS_SUBFLOTA.forEach(a => {
                if (state.operatorSubFlotaFilter.indexOf(a) === -1) {
                    isAllSelected = false;
                }
            })
        } else {
            SUMMARY_LIMMOPS_SUBFLOTA.forEach(a => {
                if (state.operatorSubFlotaFilter.indexOf(a) === -1) {
                    isAllSelected = false;
                }
            })
        }
        return isAllSelected;
    }
    const isAllSelected2 = (oper) => {
        let isAllSelected2 = true;
        if (oper === 'SUBFLOTA') {
            SUMMARY_LIMMOPS_SUBFLOTA.forEach(a => {
                if (state.operatorSubFlotaFilter.indexOf(a) === -1) {
                    isAllSelected2 = false;
                }
            })
        } else {
            SUMMARY_LIMMOPS_SUBFLOTA.forEach(a => {
                if (state.operatorSubFlotaFilter.indexOf(a) === -1) {
                    isAllSelected2 = false;
                }
            })
        }
        return isAllSelected2;
    }

    return (
        <div className="ground-support-config">
            <div className="w-title">
                <span>Summary Limops</span>
            </div>
            <Tabs className="w-tab">
                <TabList>
                    <Tab>Filters</Tab>
                    {props.isUserLimops ? <Tab>SubFleet</Tab> : ""}
                    {props.isUserLimops ? <Tab>Group</Tab> : ""}

                </TabList>

                {/* Filters section */}
                <div className='contenedor'>
                    <TabPanel>
                        <div className="desc">Select what you want to see on Summary Limops.</div>
                        <div className="w-filters">
                            <FormControl variant="outlined" classes={{ root: "w-select" }}>

                                <Radio
                                    checked={state.filter === 'operatorType'}
                                    onChange={handleSelectChangeRadioButtom}
                                    value="operatorType"
                                    name="radio-button"
                                />
                                <TextField
                                    select
                                    label="Select Group"
                                    onChange={handleSelectChange}
                                    variant="outlined"
                                    disabled={disabledGroup}
                                    SelectProps={{
                                        multiple: true,
                                        value: state.operatorGroupFilter,
                                        renderValue: (value) => value.join(', ')
                                    }}
                                >
                                    <MenuItem classes={{ root: 'option-all' }} value="OPER">
                                        <Checkbox color="primary" checked={isAllSelected("OPER")} />
                                        ALL
                                    </MenuItem>
                                    {SUMMARY_LIMMOPS_GROUP.map((arprt) => (
                                        <MenuItem classes={{ root: 'w-option' }} key={arprt} value={arprt}>
                                            <Checkbox color="primary" checked={state.operatorGroupFilter.indexOf(arprt) > -1} />
                                            {arprt}
                                        </MenuItem>
                                    ))}

                                </TextField>
                                <br></br>
                                <Radio
                                    checked={state.filter === 'subflotaOwner'}
                                    onChange={handleSelectChangeRadioButtom}
                                    value="subflotaOwner"
                                    name="radio-button"
                                />
                                <TextField
                                    select
                                    disabled={disabledFlota}
                                    label="Select SubFlota"
                                    onChange={handleSelectChangeSubFlota}
                                    variant="outlined"
                                    SelectProps={{
                                        multiple: true,
                                        value: state.operatorSubFlotaFilter,
                                        renderValue: (value) => value.join(', ')
                                    }}
                                >
                                    <MenuItem classes={{ root: 'option-all' }} value="SUBFLOTA">
                                        <Checkbox color="primary" checked={isAllSelected2("SUBFLOTA")} />
                                        ALL
                                    </MenuItem>
                                    {SUMMARY_LIMMOPS_SUBFLOTA.map((arprt) => (
                                        <MenuItem classes={{ root: 'w-option' }} key={arprt} value={arprt}>
                                            <Checkbox color="primary" checked={state.operatorSubFlotaFilter.indexOf(arprt) > -1} />
                                            {arprt}
                                        </MenuItem>
                                    ))}

                                </TextField>

                            </FormControl>
                        </div>
                        <div className="cf-buttons">
                            <div className="last-mod">
                                Last modification made: {state.lastModDate ? <Moment format="DD/MM/yyyy HH:mm" tz="UTC">{state.lastModDate}</Moment> : null}
                            </div>
                            <Button variant="contained" classes={{ root: 'vw-button' }} onClick={saveChanges} disableElevation>
                                Apply changes to video wall
                            </Button>
                        </div>

                    </TabPanel>
                </div>
                <div className='contenedor'>
                    <TabPanel>
                        <div className="desc">Set how many you want the Limit in Summary Limops.</div>
                        <div className="w-filters">
                            <SummaryLimopsMaintainer></SummaryLimopsMaintainer>
                        </div>

                    </TabPanel>
                </div>
                <div className='contenedor'>
                    <TabPanel>
                        <div className="desc">Set how many you want the Limit in Summary Limops.</div>
                        <div className="w-filters">
                            <SummaryLimopsMaintainerGroup></SummaryLimopsMaintainerGroup>
                        </div>

                    </TabPanel>
                </div>
            </Tabs>

            {/* Generic Snackbar */}
            {snackbar ? <GenericSnackbar key={snackbar.date} severity={snackbar.severity} message={snackbar.msg} /> : null}
            <Prompt when={isDirty} message='Custom prompt' />
        </div>
    );
}

export default SummaryLimopsConfig;