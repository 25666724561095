import React from "react";
import './GenericDialog.css'
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from '@material-ui/core/DialogTitle';

function GenericDialog(props) {

  const cancel = () => props.cleanUp(false);
  const ok = () => props.cleanUp(true);

  return (
      <Dialog open={props.open} onClose={cancel} classes={{paper: 'dialog'}}>
        <DialogTitle disableTypography={true} classes={{root: 'dialog-title'}}>{props.title}</DialogTitle>
        <DialogContent classes={{root: 'dialog-content'}}>
            {props.desc}
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} classes={{root: 'dialog-button'}}>
            {props.cancelMsg}
          </Button>
          <Button onClick={ok} classes={{root: 'dialog-button'}} autoFocus>
            {props.okMsg}
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default GenericDialog;
