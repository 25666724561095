import React, { Component } from 'react';
import './GenericCounter.css';

class GenericCounter extends Component {
  
  // constructor(props) {
  //   super(props);
    
  // }

  render(){
    return(
      <div className={this.props.cName}>
        <p className="counter-title">{this.props.title}</p>
        <p>{this.props.count}</p>
      </div>
    );
  }

}

export default GenericCounter;