import Button from "@material-ui/core/Button";
import React from "react";
import Moment from "react-moment";
import { updateSummaryLimopsGoals } from "../../../../firebase";
import GenericSnackbar from "../../../GenericSnackbar/GenericSnackbar";
import "./CrewStandbyMaintainer.css";

function CrewStandbyMaintainer(props) {
  const [snackbar, setSnackbar] = React.useState("");

  const saveChanges = () => {
    const newGoals = { ...props.crewStandby }; // Crear una copia del estado actual
    const modDate = new Date();
    updateSummaryLimopsGoals({
      data: newGoals,
      lastUpdate: modDate.toISOString(),
    }).then(
      () => {
        setSnackbar({
          msg: "Succesfully saved changes!",
          date: new Date(),
          severity: "success",
        });
      },
      (error) => {
        console.error(error);
        setSnackbar({
          msg: "Error saving changes. Try again later",
          date: new Date(),
          severity: "error",
        });
      }
    );
  };

  return (
    <div className="pulento">
      <div className="crew-standby-main">
        <div style={{ height: "100%", padding: ".5vh" }}>
          <div className="csb-header">
            <div className="">Para Turnos Casa:</div>
            <div className="">AM</div>
            <div className="">PM</div>
            <div className="">ATO</div>
            <div className="">T24</div>
          </div>

          <div className="csb-body">
            <div className=""></div>
            <div className="AM">
              <p>Mando</p>
              <p>Cabina</p>
            </div>
            <div className="PM">
              <p>Mando</p>
              <p>Cabina</p>
            </div>
            <div className="ATO">
              <p>
                Mando <br /> Cabina
              </p>
              <p>
                Mando <br /> Cabina
              </p>
            </div>
            <div className="T24">
              <p>
                Mando <br /> Cabina
              </p>
              <p>
                Mando <br /> Cabina
              </p>
            </div>
          </div>

          <div className="csb-color">
            <div className=""></div>
            <div className="AM">
              <p className="semaforo yelloww"></p>
              <p className="semaforo redd"></p>
              <p className="semaforo yelloww"></p>
              <p className="semaforo redd"></p>
            </div>
            <div className="PM">
              <p className="semaforo yelloww"></p>
              <p className="semaforo redd"></p>
              <p className="semaforo yelloww"></p>
              <p className="semaforo redd"></p>
            </div>
            <div className="ATO">
              <p className="semaforo yelloww"></p>
              <p className="semaforo yelloww"></p>
              <p className="semaforo yelloww"></p>
              <p className="semaforo yelloww"></p>
              <p className="semaforo redd"></p>
              <p className="semaforo redd"></p>
              <p className="semaforo redd"></p>
              <p className="semaforo redd"></p>
            </div>
            <div className="T24">
              <p className="semaforo yelloww"></p>
              <p className="semaforo redd"></p>
              <p className="semaforo yelloww"></p>
              <p className="semaforo redd"></p>
            </div>
          </div>

          {props.crewStandby?.map((ct, index) => (
            <div className="csb-color" key={`${ct.idx}-${index}`}>
              <div className="">
                {ct.subFleetCode}
                {ct.companyCode.substring(0, 2)}
              </div>
              <div className="AM">
                <p className="semaforo">{ct.amEffective || 0}</p>
                <p className="semaforo">{ct.amRemainingCabin || 0}</p>
                <p className="semaforo">{ct.amEffective || 0}</p>
                <p className="semaforo">{ct.amRemainingCabin || 0}</p>
              </div>
              <div className="PM">
                <p className="semaforo">{ct.pmEffective || 0}</p>
                <p className="semaforo">{ct.pmRemainingCabin || 0}</p>
                <p className="semaforo">{ct.pmEffective || 0}</p>
                <p className="semaforo">{ct.pmRemainingCabin || 0}</p>
              </div>
              <div className="ATO">
                <p className="semaforo">{1}</p>
                <p className="semaforo">{0}</p>
                <p className="semaforo">{1}</p>
                <p className="semaforo">{5}</p>
                <p className="semaforo">{0}</p>
                <p className="semaforo">{5}</p>
                <p className="semaforo">{0}</p>
                <p className="semaforo">{5}</p>
              </div>
              <div className="T24">
                <p className="semaforo">-</p>
                <p className="semaforo">-</p>
                <p className="semaforo">1</p>
                <p className="semaforo">-</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="cf-buttons">
        <div className="last-mod">
          Last modification made:{" "}
          {props.lastState ? (
            <Moment format="DD/MM/yyyy HH:mm" tz="UTC">
              {props.lastState}
            </Moment>
          ) : null}
        </div>
        <Button
          variant="contained"
          classes={{ root: "vw-button" }}
          onClick={saveChanges}
          disableElevation
        >
          Apply changes to video wall
        </Button>
      </div>
      {/* Generic Snackbar */}
      {snackbar ? (
        <GenericSnackbar
          key={snackbar.date}
          severity={snackbar.severity}
          message={snackbar.msg}
        />
      ) : null}
    </div>
  );
}

export default CrewStandbyMaintainer;
