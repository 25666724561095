import React, { useEffect } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import './CriticalAircraftConfig.css';
import { Prompt } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import AppsIcon from '@material-ui/icons/Apps';
import GenericSnackbar from '../../GenericSnackbar/GenericSnackbar';
import usePreventReload from '../../../hooks/usePreventReload';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';

import { FLEETS_LIST, TAILS_LIST, MAINTENANCE_AIRCRAFT_STATUS, AIRCRAFT_STATUS_SORT, AIRCRAFT_STATUS } from '../../../utils/Constants';
import { FormControlLabel } from '@material-ui/core';

function CriticalAircraft(props) {
    const [isDirty, setDirty, setClean] = usePreventReload();
    const [state, setState] = React.useState(props.configurationData ? props.configurationData : {
        excludedFleets: [],
        excludedTails: [],
        excludedStatus: [],
        lastModDate: '',
        alertSort: AIRCRAFT_STATUS_SORT,
        statusSort: MAINTENANCE_AIRCRAFT_STATUS,
    });
    const [initialAlertSort, setInitialAlertSort] = React.useState(state.alertSort);
    const [initialStatusSort, setInitialStatusSort] = React.useState(state.statusSort);

    useEffect(() => {
        setCheckBoxFirstTime();
    })

    const setCheckBoxFirstTime = () => {
        if (window.localStorage.getItem('isFirstTime') == null) {
            props.updateConfigurationData('checkedCarga', true).then(() => {
                setCheckedCarga(true)
            }, error => {
                console.error(error);
            });
            props.updateConfigurationData('checkedPax', true).then(() => {
                setCheckedPax(true)
            }, error => {
                console.error(error);
            });
            window.localStorage.setItem('isFirstTime', false)
        }
    }

    const onDragEndAlert = (result) => {
        if (!result.destination) {
            return;
        }
        const itemsAlert = Array.from(initialAlertSort);
        const [reorderedItem] = itemsAlert.splice(result.source.index, 1);
        itemsAlert.splice(result.destination.index, 0, reorderedItem);
        setInitialAlertSort(itemsAlert); // Actualiza el estado con el nuevo orden
        setDirty();
        setState({ ...state, alertSort: itemsAlert });
    };
    const onDragEndStatus = (result) => {
        if (!result.destination) {
            return;
        }
        const itemsStatus = Array.from(initialStatusSort);
        const [reorderedItem] = itemsStatus.splice(result.source.index, 1);
        itemsStatus.splice(result.destination.index, 0, reorderedItem);
        setInitialStatusSort(itemsStatus); // Actualiza el estado con el nuevo orden
        setDirty();
        setState({ ...state, statusSort: itemsStatus });
    };
    const handleChangeFleets = (event, value, action) => {
        setDirty();
        setState({ ...state, excludedFleets: value });
    };

    const handleChangeTails = (event, value, action) => {
        setDirty();
        setState({ ...state, excludedTails: value });
    };

    const handleChangeAircraftStatus = (event, value, action, checked) => {
        setDirty();
        // setState({ ...state, excludedStatus: handleSelectAll(value, action, checked) });
        setState({ ...state, excludedStatus: value });
    };

    const [snackbar, setSnackbar] = React.useState('');
    const saveChanges = () => {
        const modDate = new Date();
        state.lastModDate = modDate.toUTCString();
        props.updateConfigurationData('criticalAircraftConfig', state).then(() => {
            setSnackbar({ msg: "Succesfully saved changes!", date: new Date(), severity: "success" });
            setState({ ...state, lastModDate: modDate });
            setClean();
        }, error => {
            console.error(error);
            setSnackbar({ msg: "Error saving changes. Try again later", date: new Date(), severity: "error" });
        });
        props.updateConfigurationData('checkedCarga', checkedCarga).then(() => {
        }, error => {
            console.error(error);
        });
        props.updateConfigurationData('checkedPax', checkedPax).then(() => {
        }, error => {
            console.error(error);
        });
    }

    const [checkedCarga, setCheckedCarga] = React.useState(props.configurationCheckbox.checkedCarga);
    const handleChangeCarga = (event) => {
        setCheckedCarga(event.target.checked);
    };

    const [checkedPax, setCheckedPax] = React.useState(props.configurationCheckbox.checkedPax);
    const handleChangePax = (event) => {
        setCheckedPax(event.target.checked);
    };

    return (
        <div className="critical-aircraft-config">
            <div className="cf-title">
                <span>Critical Aircraft</span>
            </div>

            <Tabs className="cf-tab">
                <TabList>
                    <Tab>Filters</Tab>
                    <Tab>Sort</Tab>
                </TabList>

                {/* Filters section */}
                <TabPanel>
                    <div className="desc">Select what you <b>do not want</b> to see in this section of Video Wall.</div>
                    <div className="cf-filters">
                        {/* Excluded Fleet */}
                        <div className="cf-autocomplete">
                            <div className="ac-label">Excluded Fleets</div>
                            <Autocomplete
                                multiple
                                freeSolo
                                limitTags={3}
                                id="multiple-limit-tags-fleets"
                                options={FLEETS_LIST}
                                classes={{ tag: 'ac-tag' }}
                                disableCloseOnSelect={true}
                                renderOption={(option, { selected }) => (
                                    <div className='option'>
                                        <Checkbox
                                            color="primary" classes={{ root: 'ac-checkbox' }}
                                            style={{ marginRight: '1vh' }}
                                            checked={selected}
                                            value={option}
                                        />
                                        {option}
                                    </div>
                                )}
                                value={state.excludedFleets}
                                onChange={handleChangeFleets}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Fleet" />)}
                            />
                        </div>
                        {/* Excluded Tail */}
                        <div className="cf-autocomplete">
                            <div className="ac-label">Excluded Tails</div>
                            <Autocomplete
                                multiple
                                freeSolo
                                limitTags={3}
                                id="multiple-limit-tags-tails"
                                options={TAILS_LIST}
                                classes={{ tag: 'ac-tag' }}
                                disableCloseOnSelect={true}
                                renderOption={(option, { selected }) => (
                                    <div className='option'>
                                        <Checkbox
                                            color="primary" classes={{ root: 'ac-checkbox' }}
                                            style={{ marginRight: '1vh' }}
                                            checked={selected}
                                            value={option}
                                        />
                                        {option}
                                    </div>
                                )}
                                value={state.excludedTails}
                                onChange={handleChangeTails}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Tail" />)}
                            />
                        </div>
                        {/* Excluded Status */}
                        <div className="cf-autocomplete">
                            <div className="ac-label">Excluded Status</div>
                            <Autocomplete
                                multiple
                                freeSolo
                                limitTags={3}
                                id="multiple-limit-tags-status"
                                options={AIRCRAFT_STATUS}
                                classes={{ tag: 'ac-tag' }}
                                disableCloseOnSelect={true}
                                renderOption={(option, { selected }) => (
                                    <div className='option'>
                                        <Checkbox
                                            color="primary" classes={{ root: 'ac-checkbox' }}
                                            style={{ marginRight: '1vh' }}
                                            value={option}
                                            checked={selected}
                                        />
                                        {option}
                                    </div>
                                )}
                                value={state.excludedStatus}
                                onChange={handleChangeAircraftStatus}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Status" />)}
                            />
                        </div>
                    </div>
                    <div className="cf-filters block">
                        {/* Show/hide Cargueros */}
                        <div className='option'>
                            <div className="ac-label">Show Carga</div>
                            <FormControlLabel
                                control={<Checkbox checked={checkedCarga} onChange={handleChangeCarga} />}
                            />
                        </div>
                    </div>

                    <div className="cf-filters block">
                        {/* Show/hide Cargueros */}
                        <div className='option'>
                            <div className="ac-label">Show Pax</div>
                            <FormControlLabel
                                control={<Checkbox checked={checkedPax} onChange={handleChangePax} />}
                            />
                        </div>
                    </div>
                </TabPanel>
                {/* Sorts section */}
                <TabPanel>
                    <div className="desc">Order the priority of Critical Aircraft.</div>
                    <div className="cf-sorts">
                        {/* Alert sort */}
                        <div className="cf-drag-drop-sort">
                            <div className="sort-label">Alert</div>
                            <div className="drag-drop-body">

                                <DragDropContext onDragEnd={onDragEndAlert} className="drag-drop-body" >
                                    <Droppable droppableId="droppable">

                                        {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {initialAlertSort.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps} className="drag-drop-item">
                                                                <div className="number">{index + 1}</div>
                                                                <div className="option">{item.name} <AppsIcon className='icon' /></div>
                                                            </div>

                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>

                                <div
                                    items={state.alertSort ? state.alertSort : AIRCRAFT_STATUS_SORT}
                                    itemRenderer={(alert, index) => {
                                        return (
                                            <div className="drag-drop-item">
                                                <div className="number">{index + 1}</div>
                                                <div className="option">{alert.name} <AppsIcon className='icon' /></div>
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        {/* Status sort */}
                        <div className="cf-drag-drop-sort">
                            <div className="sort-label">Status</div>
                            <div className="drag-drop-body">
                                <DragDropContext onDragEnd={onDragEndStatus} >
                                    <Droppable droppableId="droppable" >
                                        {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {initialStatusSort.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps} className="drag-drop-item">
                                                                <div className="number">{index + 1}</div>
                                                                <div className="option">{item.name} <AppsIcon className='icon' /></div>
                                                            </div>

                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </Tabs>

            {/* Buttons section */}
            <div className="cf-buttons">
                <div className="last-mod">
                    Last modification made: {state.lastModDate ? <Moment format="DD/MM/yyyy HH:mm" tz="UTC">{state.lastModDate}</Moment> : null}
                </div>
                <Button variant="contained" classes={{ root: 'vw-button' }} onClick={saveChanges} disableElevation>
                    Apply changes to video wall
                </Button>
            </div>

            {/* Generic Snackbar */}
            {snackbar ? <GenericSnackbar key={snackbar.date} severity={snackbar.severity} message={snackbar.msg} /> : null}
            {/* Custom Warning Prompt */}
            <Prompt when={isDirty} message='Custom prompt' />
        </div>
    );

}

export default CriticalAircraft;