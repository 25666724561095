import React from 'react';
import './Options.css';
import LatamLogo from '../../../vector/latam-logo.svg';
import Button from '@material-ui/core/Button';
import { auth } from '../../../firebase';
import { useHistory } from "react-router-dom";

function Options() {
  const history = useHistory();

  const logout = () => {
    auth.signOut().then( () => {history.push('/login')});
  }

  return (

  <div className="vw-options">
    <div className="vw-options-header">
      <img src={LatamLogo} alt="error"></img>
    </div>
    <div className="vw-options-content">
      <Button className="options-back" variant="contained" color="primary" onClick={logout} disableElevation>← &nbsp;Back</Button>
      <div className="options-button">
        <p className="options-btn-title">Video Wall</p>
        <Button className="options-enter" variant="contained" color="primary" onClick={() =>  history.push('/')}>Enter</Button>
      </div>
      <div className="options-button">
        <p className="options-btn-title">OTP</p>
        <Button className="options-enter" variant="contained" color="primary" onClick={() =>  history.push('/otp')}>Enter</Button>
      </div>      
    </div>
    
  </div>

  );
}

export default Options;