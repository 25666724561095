import { Button } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import './NewsModal.css';
import moment from 'moment';
import GenericSnackbar from '../../../GenericSnackbar/GenericSnackbar';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
}));


function NewsModal(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({message: props.data?.message, count: props.data? props.data.message?.length : 0, startDate: props.data?.startDate, endDate: props.data?.endDate});
  const [snackbar, setSnackbar] = React.useState('');

  const onChangeText = (event) => {
    setState({...state, message: event.target.value, count: event.target.value.length})
  }

  const onChangeStart = (event) => {
    setState({...state, startDate: event.target.value})
  }

  const onChangeEnd = (event) => {
    setState({...state, endDate: event.target.value})
  }

  const save = () => {
    if (state.message !== '' && state.startDate && state.endDate) {
      if (moment.utc(state.endDate).isBefore(moment.utc(state.startDate))) {
        setSnackbar({ msg: "Error! End Date is BEFORE Start Date", date: new Date(), severity: "error" });
      } else {        
        const data = {id: props.data?.id, message: state.message, startDate: state.startDate, endDate: state.endDate};
        props.save(data, props.action);
      }
    } else {
      setSnackbar({ msg: "Error! All inputs are required", date: new Date(), severity: "error" });
    }
  }

  return (
    <div className="news-modal-style">
      <br/>
      <div className="modal-title">Post<div className="char-count"><b>{state.count}</b> characters</div></div>
      <textarea className="modal-message-content" maxLength="200" value={state.message} onChange={onChangeText}/>
      <hr />
      <p className="modal-pub-label">Select publication date</p>
      <form className={classes.container} noValidate>
      <TextField
        id="startdatetime-local"
        label="Start Date (UTC)"
        type="datetime-local"
        defaultValue={state.startDate}
        className={classes.textField}
        onChange={onChangeStart}       
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="enddatetime-local"
        label="End Date (UTC)"
        type="datetime-local"
        defaultValue={state.endDate}
        className={classes.textField}
        onChange={onChangeEnd}
        inputProps={{
          min: state.startDate
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form>
    <br /><br />
    <Button className="modal-post-btn" onClick={save}>Post Message</Button>
    {/* Generic Snackbar */}
    {snackbar ? <GenericSnackbar key={snackbar.date} severity={snackbar.severity} message={snackbar.msg}/> : null}
  </div>
  );
}

export default NewsModal;