import React from 'react';
import Moment from 'react-moment';
import './Flight.css'
import { Timer } from '../../../Timer/Timer';
import Arrival from '../../../../vector/flightstatus/arrival.svg';
import Scheduled from '../../../../vector/flightstatus/schedule2.svg';
import OnFlight from '../../../../vector/flightstatus/onflight.svg';
import Tetaxeo from '../../../../vector/flightstatus/tetaxeo.svg';
import Cancelled from '../../../../vector/flightstatus/cancelled.svg';
import ConnectingPax from '../../../../vector/connecting-passengers.svg';
import Crew from '../../../../vector/crew.svg';
import Pax from '../../../../vector/passengers.svg';
import HVC from '../../../../vector/hvc-star.svg';
import IconFlight from '../../../../vector/icon-on-flight-sm.svg';
import RiskIcon from '../../../../vector/risk-icon.svg';


function Flight(props) {
  let impactListSplit = [];
  let paxMCListSplit = [];
  let statusImgSrc = Arrival;

  if (props.flight.operationStatus === "Arrived") {
    statusImgSrc = Arrival;
  }
  else if (props.flight.operationStatus === "-") {
    statusImgSrc = Cancelled;
  }
  else if (props.flight.operationStatus === "On Flight") {
    statusImgSrc = OnFlight;
  }
  else if (props.flight.operationStatus === "Taxi") {
    statusImgSrc = Tetaxeo;
  }
  else if (props.flight.operationStatus === "Delay") {
    statusImgSrc = Scheduled;
  }

  if (props.flight.paxMCGroupList) {
    const paxMCList = props.flight.paxMCGroupList.reduce((rows, key, index) => {
      return (index % 2 === 0 ? rows.push([key])
        : rows[rows.length - 1].push(key)) && rows;
    }, []);
    if (paxMCList.length > 3) {
      while (paxMCList.length > 0) {
        paxMCListSplit.push(paxMCList.splice(0, 3));
      }
    } else {
      paxMCListSplit = paxMCList;
    }
  }

  if (props.flight.impactList && props.flight.impactList.length > 3) {
    const impactList = props.flight.impactList.slice();
    while (impactList.length > 0) {
      impactListSplit.push(impactList.splice(0, 3));
    }
  } else {
    impactListSplit = props.flight.impactList;
  }

  return (
    <div className="box1">

      <div className="item1 card-segment">
        <div className="item1-left sub-segment">
          <img src={statusImgSrc} alt="error"></img>
          <p className="cf-status">{props.flight.operationStatus}</p>
          {props.flight.alertAlt !== 'ETD' ? <p className="gtb">{props.flight.alertAlt}</p> : null}
        </div>
        <div className="item1-right sub-segment">
          <p className="plane-flight-date">{<Moment format="DD/MM/yyyy">{props.flight.dprtrDateTime}</Moment>}</p>
          <p className="plane-number">{props.flight.fltCode}</p>
          <p className="plane-model-flight">{props.flight.cdgMatricula}&nbsp;{props.flight.sbFlota}</p>
        </div>
      </div>

      <div className="vr"></div>

      <div className="item2 card-segment">
        <p className="item2-cities">{props.flight.dprtrAirport} &emsp;&emsp; {props.flight.arrvlAirport}</p>
        <p className="item2-time">{<Moment format="HH:mm">{props.flight.dprtrDateTime}</Moment>}&ensp;&nbsp;&gt;&nbsp;&ensp;{<Moment format="HH:mm">{props.flight.arrvlDateTime}</Moment>}</p>
        {props.flight.alertAlt === 'ETD' ? <div className="item2-arrival"> <Timer expiryTimestamp={props.flight.dprtrDateTime} /> </div> : null}
      </div>

      <div className="vr"></div>

      <div className="item3 card-segment">
        <p className="item3-title">Reason</p>
        {props.flight.reason ?
          <div className="item3-reason">{props.flight.reason}</div>
          : <div className="no-data"><img src={RiskIcon} alt="" width="30" /></div>}
      </div>

      <div className="vr"></div>

      <div className="item4 card-segment">

        <div className="item4-box">
          <img src={ConnectingPax} alt="error"></img>
          <div className="item4-labels">
            <p className="item4-number">{props.flight.paxNumber}</p>
            <p className="item4-desc">PAX</p>
          </div>
        </div>

        <div className="item4-box">
          <img src={Crew} alt="error"></img>
          <div className="item4-labels">
            <p className="item4-number">{props.flight.paxConexNumber}</p>
            <p className="item4-desc">PAX MC</p>
          </div>
        </div>

        <div className="item4-box">
          <img src={Pax} alt="error"></img>
          <div className="item4-labels">
            <p className="item4-number">{props.flight.crewNumber}</p>
            <p className="item4-desc">CREW</p>
          </div>
        </div>

        <div className="item4-box">
          <img src={HVC} alt="error"></img>
          <div className="item4-labels">
            <p className="item4-number">{props.flight.hvcNumber}</p>
            <p className="item4-desc">HVC</p>
          </div>
        </div>

      </div>

      <div className="vr"></div>

      <div className="item5 card-segment">
        <p className="item5-title">Next leg</p>
        {props.flight.nextLegFltCode ?
          <div className="item5-inner-div">
            <p className="item5-flight">{props.flight.nextLegFltCode}</p>
            <p className="item5-route">{props.flight.nextLegDprtrAirport} / {props.flight.nextLegArrvlAirport}</p>
            <p className="item5-time">{<Moment format="HH:mm">{props.flight.nextLegDprtrTime}</Moment>} <img src={IconFlight} alt="error"></img></p>
          </div>
          : <div className="no-data">-</div>}
      </div>

      <div className="vr"></div>

      <div className="item6 card-segment">
        <p className="item6-title">Crew connection</p>
        {props.flight.crewConnFltCode ?
          <>
            {props.flight.crewConnTime ? <div className="item6-time"><Timer expiryTimestamp={props.flight.crewConnTime} /> </div> : null}
            <p className="item6-flight">{props.flight.crewConnFltCode}</p>
          </>
          : <div className="cf-no-data">-</div>}
      </div>

      <div className="vr display-none"></div>

      <div className="item7 card-segment">
        <p className="item7-title">Crew Time</p>
        <p className="item7-line"><span className="item7-code"> &nbsp;</span><span className="item7-hr"></span></p>
        <p className="item7-line"><span className="item7-code"> &nbsp;</span><span className="item7-hr"></span></p>
      </div>

    </div>


  );
}

export default Flight;