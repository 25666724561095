import React, { Component } from 'react';
import './CriticalFlightCounter.css';

class CriticalFlightCounter extends Component {
  
  // constructor(props) {
  //   super(props);
    
  // }

  render(){
    return(
      <div className="cf-counter">
        <p className="cf-counter-title">{this.props.title}</p>
        <p className="cf-num">{this.props.count}</p>
      </div>
    );
  }

}

export default CriticalFlightCounter;