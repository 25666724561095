let weatherSlideIndex = 1;
weatherShowSlides(weatherSlideIndex);

const weatherCurrentSlide = (n) => {
  weatherShowSlides(weatherSlideIndex = n);
}

function weatherShowSlides(n) {
  let i;
  let slides = document.getElementsByClassName("weather-mySlides");
  let dots = document.getElementsByClassName("weather-dot");
  
  if (n > slides.length) {weatherSlideIndex = 1}

  if (n < 1) {weatherSlideIndex = slides.length}

  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }

  for (i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(" active", "");
  }
  if(slides[weatherSlideIndex-1] !== undefined){
    slides[weatherSlideIndex-1].style.display = "block";  
    dots[weatherSlideIndex-1].className += " active";
  }
}

// weather-carousell-dots
// weather-dot

// weather-mySlides weather-fade (body)

export { weatherCurrentSlide }