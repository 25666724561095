import React from 'react';
import './CriticalAircraftCounter.css';

function CriticalAircraftCounter(props) {

  const style = {
    borderColor: props.color,
  }

  return (
      <div className="ca-counter" style={style}>
        <p className="ca-counter-title">{props.title}</p>
        <p className="ca-counter-count">{props.count}</p>
      </div>
    );
}

export default CriticalAircraftCounter;