import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import moment from 'moment';
import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import NewsModal from './NewsModal/NewsModal';
import GenericDialog from '../../GenericDialog/GenericDialog';
import GenericSnackbar from '../../GenericSnackbar/GenericSnackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import './DailyNews.css';
import { firestore, streamNews } from '../../../firebase';

const columns = [
  { id: 'message', label: '' },
  { id: 'startDate', label: 'Start Date (UTC)' },
  { id: 'endDate', label: 'End Date (UTC)' },
  { id: 'status', label: 'Status' },
  { id: 'edit', label: '' },
  { id: 'delete', label: '' },
];

let isLoading = true;
let newsList = [];
let intervalId;

function DailyNews(props){
  const [state, setState] = useState({newsList: [], open: false, page: 0, rowsPerPage: 5});
  const [snackbar, setSnackbar] = React.useState('');
  const userCCO = props.userCCO;

  useEffect(() => {
    const unsubscribe = streamNews(userCCO, {
      next: (querySnapshot) => {
        newsList = [];
        if (querySnapshot.data()) {
          newsList = querySnapshot.data().data ? querySnapshot.data().data : [];
        }
        isLoading = false;
        updateStatus(newsList);
      },
      error: (e) => {
        console.error(e);
      }
    });
    return unsubscribe;
  }, [userCCO]);

  useEffect(() => {
    if (!intervalId) {
      intervalId = setInterval( () => updateStatus(newsList), 60000);
    }
    return cleanUpInterval;
  }, []);

  const cleanUpInterval = () => {
    clearInterval(intervalId);
    intervalId = null;
  }

  const handleOpen = () => {
    setState({ ...state, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleChangePage = (event, newPage) => {
    setState({ ...state, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({ ...state, page: 0,  rowsPerPage: +event.target.value});
  };

  const updateStatus = (newsList) => {
    newsList.forEach(n => {
      if (moment.utc().isBetween(moment.utc(n.startDate), moment.utc(n.endDate), 'minutes', '[]')) {
        n.status = 'ACTIVE';
      } else if (moment.utc(n.endDate).isBefore(moment.utc())){
        n.status = 'EXPIRED';
      } else {
        n.status = 'PENDING';
      }
    });
    setState(prevState => ({...prevState, newsList: newsList}));
  }

  const saveNews = async (data, action) => {
    if (action === 'edit') {
      const index = state.newsList.findIndex(e => data.id === e.id);
      if (index > -1) {
        state.newsList[index] = data;
      }
    } else if (action === 'delete') {
      const index = state.newsList.findIndex(e => data.id === e.id);
      if (index > -1) {
        state.newsList.splice(index, 1);
      }
    } else {
      if (state.newsList.length > 0) {
        data.id = state.newsList.reduce((prev, current) => (prev.id > current.id) ? prev : current, 1).id + 1;
      } else {
        data.id = 1;
      }
      state.newsList.push(data);
    }
    const modDate = new Date();
    setState({ ...state, open: false});
    await firestore.collection('videowall-headerData').doc('news' + userCCO.toUpperCase()).set({data: state.newsList, lastModDate: modDate.toUTCString()});
    return true;
  }

  const deleteNews = async (data) => {
    saveNews(data, 'delete').then(() =>{
      setSnackbar({ msg: "Succesfully deleted message!", date: new Date(), severity: "success" });
    }, error => {
      console.error(error);
      setSnackbar({ msg: "Error deleting message. Try again later", date: new Date(), severity: "error" });
    });
  }

  const save = (data, action) => {
    saveNews(data, action).then(() =>{
      setSnackbar({ msg: "Succesfully saved message!", date: new Date(), severity: "success" });
    }, error => {
      console.error(error);
      setSnackbar({ msg: "Error saving message. Try again later", date: new Date(), severity: "error" });
    });
  }

  return(
    <div className="daily-news-root">
      {!isLoading && 
      <>
      {/* Create/Edit Dialog */}
      <Dialog maxWidth='sm' open={state.open} onClose={handleClose}>
        <NewsModal save={save} action="create"/>
      </Dialog>

      <div className="df-title">
        <span>Daily News</span>
      </div>
      <p className="desc">Create and publish news to be displayed on top of Video Wall.</p>
      <hr />
      <div className="create-post-card">
        <p className="create-post-title">Create Post</p>
        <p className="create-label" onClick={handleOpen}>Write a message with a maximum of 200 characters.</p>
      </div>
      <br />
      <Tabs className="dn-tab">
        <TabList >
          <Tab>Active Messages</Tab>
          <Tab>Pending Messages</Tab>
          <Tab>Expired Messages</Tab>
        </TabList>
        {/* Active section */}
        <TabPanel>
          <div className="message-container">
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} classes={{root: 'mess-heads'}} >
                    <div className={"mess-head-"+column.id}>{column.label}</div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {state.newsList.filter(e => e.status === 'ACTIVE').slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage).map((row, index) => {
                return (<Message key={row.id} data={row} save={save} deleteNews={deleteNews}/>)
              })}
            </TableBody>
          </Table>
          <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              classes={{caption: "messages-table-pagination", selectRoot: "messages-table-pagination", select: "messages-table-pagination"}}
              count={state.newsList.filter(e => e.status === 'ACTIVE').length}
              rowsPerPage={state.rowsPerPage}
              page={state.page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </TabPanel>
        {/* Pending section */}
        <TabPanel>
          <div className="message-container">
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} classes={{root: 'mess-heads'}} >
                    <div className={"mess-head-"+column.id}>{column.label}</div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {state.newsList.filter(e => e.status === 'PENDING').slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage).map((row, index) => {
                return (<Message key={row.id} data={row} save={save} deleteNews={deleteNews}/>)
              })}
            </TableBody>
          </Table>          
          <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              classes={{caption: "messages-table-pagination", selectRoot: "messages-table-pagination", select: "messages-table-pagination"}}
              count={state.newsList.filter(e => e.status === 'PENDING').length}
              rowsPerPage={state.rowsPerPage}
              page={state.page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </TabPanel>
        {/* Expired section */}
        <TabPanel>
          <div className="message-container">
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} classes={{root: 'mess-heads'}} >
                    <div className={"mess-head-"+column.id}>{column.label}</div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {state.newsList.filter(e => e.status === 'EXPIRED').slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage).map((row, index) => {
                return (<Message key={row.id} data={row} save={save} deleteNews={deleteNews}/>)
              })}
            </TableBody>
          </Table>            
          <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              classes={{caption: "messages-table-pagination", selectRoot: "messages-table-pagination", select: "messages-table-pagination"}}
              count={state.newsList.filter(e => e.status === 'EXPIRED').length}
              rowsPerPage={state.rowsPerPage}
              page={state.page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            </div>
        </TabPanel>
      </Tabs>
      </>}

      {/* Generic Snackbar */}
      {snackbar ? <GenericSnackbar key={snackbar.date} severity={snackbar.severity} message={snackbar.msg}/> : null}
    </div>  
  );

  function Message(props){
    const [state, setState] = React.useState({open: false, openDel: false});

    const handleOpen = () => {
      setState({ ...state, open: true });
    };
  
    const handleClose = () => {
      setState({ ...state, open: false });
    };
  
    const handleOpenDel = () => {
      setState({ ...state, openDel: true });
    };

    const confirmDelete = (confirm) => {
      if (confirm) {
        props.deleteNews(props.data);
      } else {
        setState({...state, openDel: false});
      }
    };
  
    return (
      <>
      {/* Create/Edit Dialog */}
      <Dialog maxWidth='sm' open={state.open} onClose={handleClose}>
        <NewsModal data={props.data} save={props.save} action="edit"/>
      </Dialog>

      {/* Delete Dialog */}
      <GenericDialog open={state.openDel} title="Confirm that you want to delete this post" desc={"\""+props.data?.message+"\""} cancelMsg="Return" 
      okMsg="Yes, delete post" cleanUp={confirmDelete}/>
      
      <TableRow classes={{root: 'message-unit'}}>
        {columns.map((column) => {
          const rowValue = props.data[column.id];
          return (
            <TableCell key={column.id}>
              {column.id === 'edit' ? <EditOutlined className="mess-ico" onClick={handleOpen}/>
              : column.id === 'delete' ? <DeleteOutlined className="mess-ico" onClick={handleOpenDel}/>
              : column.id.includes('Date') ? <div className="message-date"><Moment format="DD/MM/YYYY HH:mm">{rowValue}</Moment></div>
              : column.id === 'status' ? <div className={"message-status-"+rowValue.toLowerCase()}>{rowValue}</div>
              : <div className={"message-"+column.id}>{rowValue}</div> }
            </TableCell>
          );
        })}
      </TableRow>
      </>
    );
  }
}

export default DailyNews;