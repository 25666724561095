import React from 'react';
import CriticalFlightUnit from '../CriticalFlightUnit/CriticalFlightUnit';
import './CriticalFlightGroup.css';

function CriticalFlightGroup(props) {
  return (
    <div className="cf-mySlides cf-fade">
    
      {   
            <div className='critical-flights-cols'>
              <div  className='critical-flights-tittle critical-flights-row-style'>
                <div className='critical-flights-tittle-Bussiness'>Business</div>
                <div className='critical-flights-sep'></div>
                <div className='critical-flights-aircraft'>Aircraft</div>
                <div className='critical-flights-sep'></div>
                <div className='critical-flights-flight'>Flight</div>
                <div className='critical-flights-sep'></div>
                <div className='critical-flights-colx2'>Departure</div>
                <div className='critical-flights-sep'></div>
                <div className='critical-flights-colx2'>Arrival</div>
                <div className='critical-flights-sep'></div>
                <div className='critical-flights-Impact'>Impact</div>
              </div>
              {
              props.ctalFlightGrp.filaA.map((flight,index) => (
                <CriticalFlightUnit  {...{ flight, index }} />
              ))
              }
              
            </div>
      }
  
    </div>
  );
}
export default CriticalFlightGroup;

