import React, {useState, useEffect} from 'react';
import moment from 'moment';

function Timer( {expiryTimestamp} ) {  

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => {clearTimeout(timer)};
  });
  
  const calculateTimeLeft = () => {
    const today = moment.utc();
    const date = moment.utc(expiryTimestamp, "YYYY-MM-DD HH:mm:ss", true);
    const isExpired = today.isAfter(date.clone().add(1, 'minute'));
    const duration = moment.duration(date.diff(today))
    const timeLeft = {
      hours: Math.abs(duration.get('day')) * 24 + Math.abs(duration.get('hours')),
      minutes: Math.abs(duration.get('minutes')),
      expired: isExpired
    };

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  return (
    <div>
      <span>{timeLeft.expired? '-' : null} {timeLeft.hours > 0? timeLeft.hours + ' h' : null}  {timeLeft.minutes} min</span>
    </div>
  );

}

export { Timer }