import {OTP_SSC_GROUPS, OTP_BR_GROUPS} from '../utils/Constants';

class OTPService {

  /**
   * Apply user configuration and split into list of six
   * @param {array} dailyPunctualityList 
   * @param {object} config 
  */
  applyConfigurationAndSplit(dailyPunctualityList, config) {
    dailyPunctualityList.forEach(e=>{
     e.businessGroup = e.businessGroup?e.businessGroup:e.businessGroupHub?e.businessGroupHub:"ATAM TOTAL";
    });
    config.otpConfig?.businessGroupFilter.unshift("ATAM TOTAL"); // Se ordena alfabeticamente y debe estar primero
    let dailyPunctualityListSplit = [];
    const filteredOTP = this.filterOTP(dailyPunctualityList, config);
    filteredOTP.sort((a, b) => {
      const businessGroupA = a.businessGroup? a.businessGroup : a.businessGroupHub;
      const businessGroupB = b.businessGroup? b.businessGroup : b.businessGroupHub;
      return businessGroupA.localeCompare(businessGroupB);
    });
    while (filteredOTP.length > 0) {
      dailyPunctualityListSplit.push(filteredOTP.splice(0, 8));
    }
    return dailyPunctualityListSplit;
  }

  filterOTP(dailyPunctualityList, config){
    /** @type {array} */
    const businessGroupFilter = config.otpConfig?.businessGroupFilter;



    if (businessGroupFilter) {
      return dailyPunctualityList.filter(d => {
        const businessGroup = d.businessGroup? d.businessGroup : d.businessGroupHub;
        return businessGroupFilter.includes(businessGroup !== 'INTER' ? businessGroup : 'INTER LATAM');
      });
    } else {
      return dailyPunctualityList;
    }    
  }

  getDataByBusinessGroup(data, attribute) {
    const group = data.businessGroup ? data.businessGroup : data.businessGroupHub;
    if (OTP_SSC_GROUPS.includes(group)) {
      return data[`SSC_${attribute}`];
    } else if (OTP_BR_GROUPS.includes(group)) {
      return data[`BR_${attribute}`];
    } else {
      return data[`SSC_${attribute}`];
    }
  }

}    
export default OTPService;