import React, { useState, useEffect } from 'react';
import './CriticalFlights.css';
import CriticalFlightGroup from './CriticalFlightGroup/CriticalFlightGroup';
import GenericTitle from '../GenericTitle/GenericTitle';
import CriticalFlightCounter from './CriticalFlightCounter/CriticalFlightCounter';
import CriticalFlightsService from '../../service/CriticalFlightsService';
import Loader from '../Loader/Loader';
import { cfCurrentSlide } from '../../hooks/carrousel';
import { streamCriticalFlights } from '../../firebase';

let isLoading = true;
let actualCfSlide = 1;
let criticalFightsGroupList = [];
let criticalFightsDot = [];
let intervalId;
let lastUpdate;
const criticalFlightsService = new CriticalFlightsService();

function CriticalFlights(props) {
  const [state, setState] = useState({ currentOp: '', ctalFlightsList: [] });

  useEffect(() => {
    const unsubscribe = streamCriticalFlights({
      next: (querySnapshot) => {
        isLoading = false;
        if (querySnapshot.data()) {
          lastUpdate = querySnapshot.data().lastUpdate;
          setState({ ctalFlightsList: querySnapshot.data().data });
          moveSlide(actualCfSlide);
        } else {
          lastUpdate = new Date();
          setState({ ctalFlightsList: [] });
        }
      },
      error: (e) => {
        console.error(e);
        isLoading = false;
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    cfCurrentSlide(actualCfSlide);
    if (!intervalId) {
      intervalId = setInterval(() => moveSlide(actualCfSlide + 1), 25000);
    }
    return () => cleanUpInterval();
  }, [props]);

  const cleanUpInterval = () => {
    clearInterval(intervalId);
    intervalId = null;
  }

  const moveSlide = (slideNum) => {
    if (slideNum > criticalFightsGroupList.length) {
      slideNum = 1;
    }
    actualCfSlide = slideNum;
    setState(prevState => ({ ...prevState, currentOp: criticalFightsGroupList.length > 0 ? criticalFightsGroupList[slideNum - 1].type : '' }));
    cfCurrentSlide(slideNum);
  }

  criticalFightsGroupList = criticalFlightsService.applyConfigAndGroupByOperator(state.ctalFlightsList, props);
  criticalFightsDot = criticalFlightsService.groupByDots(criticalFightsGroupList);

  let bussinessCount = {};

  criticalFightsGroupList.forEach((ops) => {
    ops.flights.forEach((flight) => {
      let operator = (flight.operatorType ? flight.operatorType.split(' ')[0] : undefined)
      if (operator) {
        if (bussinessCount[operator] === undefined)
          bussinessCount[operator] = 1;
        else
          bussinessCount[operator] += 1;
      }
    });
  });

  let orderedBussiness = Object.entries(bussinessCount).sort((a, b) => b[1] - a[1]);

  return (
    <div className="critical-flights">
      {!isLoading && (
        <>
          <div className="critical-header">
            <GenericTitle title={"Critical Flights"} color={"#2100A7"} lastUpdate={lastUpdate} />

            <div className="cf-counter-grp">
              <div className="cf-counter-grp-b">
                {
                  orderedBussiness.map((value, index) => {
                    if (index < 4)
                      return <CriticalFlightCounter key={index} title={value[0]} count={value[1]} />
                    else
                      return null;
                  })
                }
              </div>
            </div>
          </div>


          <div className="critical-flights-main">
            {
              criticalFightsDot.map((ctalFlightGrp, indx) => (
                <CriticalFlightGroup key={indx} {...{ ctalFlightGrp }} />
              ))
            }

          </div>
          <div className="cf-carousell-dots">
            {
              criticalFightsDot.map((ctalFlightGrp, indx) => (
                <span className="cf-dot" key={indx} onClick={() => moveSlide(indx + 1)}></span>
              ))
            }
          </div>
        </>
      )}

      {/* Loader */ isLoading && (<> <Loader id='ctrl-loader' /> </>)}
    </div>
  );
}

export default CriticalFlights;