import React, { useEffect, useState } from "react";
import {
  streamParSubFlota,
  streamSummaryLimmops,
  streamfleetDictionary,
} from "../../firebase";
import { slCurrentSlide } from "../../hooks/summaryLimopsCarrousel";
import SummaryLimmopsService from "../../service/SummaryLimopsService";
import GenericTitle from "../GenericTitle/GenericTitle";
import Loader from "../Loader/Loader";
import "./SummaryLimops.css";
import SummaryLimopsCounter from "./SummaryLimopsCounter/SummaryLimopsCounter";
import SummaryLimopsGroup from "./SummaryLimopsGroup/SummaryLimopsGroup";

let isLoading = true;
let actualslSlide = 1;
let summaryLimopsList = [];
let summaryLimopsCount = [];
let summaryLimopsDot = [];
let intervalId;
let lastUpdate;
let parSubFlotaList = [];
let fleetDictionaryList = [];
const summaryLimmopsService = new SummaryLimmopsService();

function SummaryLimops(props) {
  const [state, setState] = useState({ currentOp: "", summaryLimopsList: [] });
  const [parSft, setParSft] = useState({ currentOp: "", parSubFlotaList: [] });
  const [flt, setFlt] = useState({ currentOp: "", fleetDictionaryList: [] });

  useEffect(() => {
    return streamfleetDictionary({
      next: (querySnapshot) => {
        isLoading = false;
        if (querySnapshot.data()) {
          lastUpdate = querySnapshot.data().lastUpdate;
          setFlt({ fleetDictionaryList: querySnapshot.data().data });
          moveSlide(actualslSlide);
        } else {
          lastUpdate = new Date();
          setFlt({ fleetDictionaryList: [] });
        }
      },
      error: (e) => {
        console.error(e);
        isLoading = false;
      },
    });
  }, [props]);

  useEffect(() => {
    return streamParSubFlota({
      next: (querySnapshot) => {
        isLoading = false;
        if (querySnapshot.data()) {
          lastUpdate = querySnapshot.data().lastUpdate;
          setParSft({ parSubFlotaList: querySnapshot.data().data });
          moveSlide(actualslSlide);
        } else {
          lastUpdate = new Date();
          setParSft({ parSubFlotaList: [] });
        }
      },
      error: (e) => {
        console.error(e);
        isLoading = false;
      },
    });
  }, [props]);

  useEffect(() => {
    return streamSummaryLimmops({
      next: (querySnapshot) => {
        isLoading = false;
        if (querySnapshot.data()) {
          lastUpdate = querySnapshot.data().lastUpdate;
          setState({ summaryLimopsList: querySnapshot.data().data });
          moveSlide(actualslSlide);
        } else {
          lastUpdate = new Date();
          setState({ summaryLimopsList: [] });
        }
      },
      error: (e) => {
        console.error(e);
        isLoading = false;
      },
    });
  }, [props]);

  useEffect(() => {
    slCurrentSlide(actualslSlide);
    if (!intervalId) {
      intervalId = setInterval(() => moveSlide(actualslSlide + 1), 25000);
    }
    return () => cleanUpInterval();
  });
  const cleanUpInterval = () => {
    clearInterval(intervalId);
    intervalId = null;
  };

  const moveSlide = (slideNum) => {
    if (slideNum > summaryLimopsDot.length) {
      slideNum = 1;
    }
    actualslSlide = slideNum;
    setState((prevState) => ({
      ...prevState,
      currentOp:
        summaryLimopsDot.length > 0 ? summaryLimopsDot[slideNum - 1].type : "",
    }));
    slCurrentSlide(slideNum);
  };

  summaryLimopsList = state.summaryLimopsList;
  parSubFlotaList = parSft.parSubFlotaList;
  fleetDictionaryList = flt.fleetDictionaryList;
  summaryLimopsCount = summaryLimmopsService.summaryLimopsCounters(
    summaryLimopsList,
    props
  );

  summaryLimopsDot = summaryLimmopsService.operatorCounters(
    summaryLimopsList,
    props
  );

  let countAircraft =
    summaryLimmopsService.countAircraftByLimitOperationAndParSft(
      parSubFlotaList,
      fleetDictionaryList,
      props
    );

  return (
    <div className="new-summary-limops">
      {!isLoading && (
        <>
          <div className="new-summary-header">
            <GenericTitle
              title={"Summary Limops"}
              color={"darkolivegreen"}
              lastUpdate={lastUpdate}
            />
            <div className="sl-counter-grp">
              <div className="sl-counter-grp-b">
                {summaryLimopsCount.map((value, index) => {
                  if (index < 5)
                    return (
                      <SummaryLimopsCounter
                        key={index}
                        title={value["carrier"]}
                        count={value["count"]}
                      />
                    );
                  else return null;
                })}
              </div>
            </div>
          </div>
          <div className="new-summary-limops-main">
            {summaryLimopsDot.map((ctalFlightGrp, indx) => (
              <SummaryLimopsGroup
                key={indx}
                {...{ ctalFlightGrp, indx, countAircraft }}
              />
            ))}
          </div>
          <div className="sl-carousell-dots">
            {summaryLimopsDot.map((summaryLimopsGrp, indx) => (
              <span
                className="sl-dot"
                key={indx}
                onClick={() => moveSlide(indx + 1)}
              ></span>
            ))}
          </div>
        </>
      )}
      {isLoading && (
        <>
          {" "}
          <Loader id="ctrl-loader" />{" "}
        </>
      )}
    </div>
  );
}

export default SummaryLimops;
