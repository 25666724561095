import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import './CriticalFlightUnit.css';
import Carousel from 'react-material-ui-carousel'

function CriticalFlightUnit(props) {  
  let impactListSplit = [];
  if (props.flight.impactList && props.flight.impactList.length > 3) {
    const impactList = props.flight.impactList.slice();
    while (impactList.length > 0) {
      impactListSplit.push(impactList.splice(0, 3));
    }
  } else {
    impactListSplit = props.flight.impactList;
  }
  
  return(
    <div>
      <div className='critical-flights-row critical-flights-row-style'>
       <div className='critical-flights-Bussiness'>{props.flight.operatorType}</div>
        <div className='critical-flights-aircraft critical-flights-font'>{props.flight.cdgMatricula}</div>
        <div className='critical-flights-flight critical-flights-font'>{props.flight.fltCode}</div>
        <div className='critical-flights-col critical-flights-font'>{props.flight.dprtrAirport}</div>
        <div className='critical-flights-col critical-flights-time'>{<Moment format="HH:mm">{moment.utc(props.flight.dprtrDateTime, 'DD/MM/YYYY HH:mm:ss')}</Moment>}</div>
        <div className='critical-flights-col critical-flights-font'>{props.flight.arrvlAirport}</div>
        <div className='critical-flights-col critical-flights-time'>{<Moment format="HH:mm">{moment.utc(props.flight.arrvlDateTime, 'DD/MM/YYYY HH:mm:ss')}</Moment>}</div>
        <div className='critical-flights-Impact critical-flights-font'>
        {
            props.flight.impactList && props.flight.impactList.length > 3 ?
            <Carousel  navButtonsAlwaysInvisible={true} interval={7000}>
              {
                impactListSplit.map( (impactList, index) => {
                  return impactList.map( (impact, index) => { 
                    return <div key={index} className="">{impact}</div>
                  })
                })
              }
            </Carousel>
            : impactListSplit.map( (impact, index) => {
              return <div key={index} className="">{impact}</div>
            })
          }    
        </div>
      </div>
    </div>

  );  

}

export default CriticalFlightUnit;