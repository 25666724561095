class WeatherService {

  /**
   * Apply user configuration and split into list of three
   * @param {array} weatherList 
   * @param {object} config 
   */
  applyConfigurationAndSplit(weatherList, config) {
    let weatherListSplit = [];
    const filteredWeather = this.filterAirports(weatherList, config);
    filteredWeather.sort((a, b) => a.airport.localeCompare(b.airport));
    while (filteredWeather.length > 0) {
      weatherListSplit.push(filteredWeather.splice(0, 10));
    }
    return weatherListSplit;
  }

  filterAirports(weatherList, config){
    /** @type {array} */
    const airportsFilter = config.weatherConfig?.airportsFilter;

    if (airportsFilter) {
      return weatherList.filter(w => {
        return airportsFilter.includes(w.airport);
      });
    } else {
      return weatherList;
    }
  }
  
}
    
export default WeatherService;