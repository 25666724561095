import React from 'react';
import './VeoPunctuality.css';
import PercentBox from '../PercentBox/PercentBox';
import OTPService from '../../../service/OTPService';
import LatamLogoOtp from '../../../vector/latam-logo-otp.svg';
import {PARAM_GOAL} from '../../../utils/Constants';
import PercentBoxDep0 from "../PercentBoxDep0/PercentBoxDep0";

const otpService = new OTPService();

function getGoal(otpGoals,businessGoal){
  var goal;
    otpGoals?.forEach(element => {
      if(element.businessDetailGoal === PARAM_GOAL[businessGoal]){
        goal = element;
      }
    });
  return goal;
}

function MonthPunctualityVeo(props) {
  const getBusinessGroup = (data) => {
    return data.businessGroup ? data.businessGroup : data.businessGroupHub;
  }

  return(
  <div >
    <div className="veo-punct-body">
    <div className= "veo-punct-col1">
    <div className="veo-punct-rows">
  </div>
    {props.dailyPunctualityList.map((myData, indx) => {
          return (
              <div key={indx}>
              {myData.map( (itemData, index) =>
                <div key={index} className="veo-punct-rows">
                    &emsp;{getBusinessGroup(itemData)==="ATAM TOTAL"? <img className="latam-logo-veo" src={LatamLogoOtp} alt="error"></img> :getBusinessGroup(itemData)}
                  </div>)}
                  </div>)})}
    </div>
    <div className="veo-punct-col2" >  
    <div className="veo-punct-rows">
       <div className="veo-title-cell1 veo-title-font" >DEP 0' </div>
       <div className="veo-title-cell2 veo-title-font" >ARR 14 </div>
       <div className="veo-title-cell3 veo-title-font" >IMP 60 </div>
       <div className="veo-title-cell4 veo-title-font" >CNL </div>
       <div className="veo-title-cell5 veo-title-font" >LEGS </div>
       <div className="veo-title-cell6 veo-title-font">DELAY CODE</div>
      </div>

    {props.dailyPunctualityList.map((myData, indx) => {
      return (
              <div key={indx} className ="campos">
                {myData.map( (itemData, index) =>
              
                  <div key={index} className="veo-punct-rows">

                    <div className="veo-punct-cell2" >
                      <PercentBoxDep0 value={otpService.getDataByBusinessGroup(itemData, 'std0Month')} goal={getGoal(props.latamGoal,getBusinessGroup(itemData))?.monthGoalStd0} type={"dep"} isVeo/>
                      </div>
                    <span className="veo-punct-cell3">
                      {otpService.getDataByBusinessGroup(itemData, 'arr14Month')}
                      </span>
                    <div className="veo-punct-cell4">
                      <PercentBox value={otpService.getDataByBusinessGroup(itemData, 'imp60Month')} goal={getGoal(props.latamGoal,getBusinessGroup(itemData))?.monthGoalImpact60} type={"affected"} isVeo/>
                      </div>
                    <div className="veo-punct-cell5">
                      <PercentBox value={otpService.getDataByBusinessGroup(itemData, 'cnlMonth')} goal={getGoal(props.latamGoal,getBusinessGroup(itemData))?.monthGoalCNL} type={"affected"} isVeo/>
                      </div>
                    <span className="veo-punct-cell6">
                      {otpService.getDataByBusinessGroup(itemData, 'legs_month').replace(',','')}/{otpService.getDataByBusinessGroup(itemData, 'totalLegs_month').replace(',','')}
                      </span>
                    <div className="veo-punct-delays-cell">
                      {
                        Array.from(Array(3).keys()).map((value, index) =>{
                          let validDelayReason = itemData.delayReason && itemData.delayReason[index];
                          return ( <div key={index} className="delay-unit">
                            <p className = "veo-punct-delays">{validDelayReason ? itemData.delayReason[index].delayCode : null}</p>
                            <p className = "veo-punct-delays">{validDelayReason ? otpService.getDataByBusinessGroup(itemData.delayReason[index], 'countDelayReasonMonth') : '-'}</p>
                            
                          </div>)
                          })
                      }
                    </div>
                  </div>
                )}
              </div>
              )
            }
          )
        }
          </div>
        </div>
  </div> 
  );
}

export default MonthPunctualityVeo;