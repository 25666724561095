import React from 'react';
import './WeatherUnit.css';
import './WeatherUnitScroll.scss'
import Dust from '../../../vector/weather/dust.svg';
import Fog from '../../../vector/weather/fog.svg';
import Freeze from '../../../vector/weather/freeze.svg';
import Funnel from '../../../vector/weather/funnel.svg';
import Rain from '../../../vector/weather/rain.svg';
import Shallow from '../../../vector/weather/shallow.svg';
import Thund from '../../../vector/weather/thunderstorm.svg';
import All from '../../../vector/weather/all.svg';
import {WEATHER_CODES} from '../../../utils/Constants.js';

function WeatherUnit(props) {

  let icon, code;
  const mapIcons = new Map();
  mapIcons.set("rain", Rain);
  mapIcons.set("shallow", Shallow);
  mapIcons.set("freeze", Freeze);
  mapIcons.set("thund", Thund);
  mapIcons.set("fog", Fog);
  mapIcons.set("dust", Dust);
  mapIcons.set("funnel", Funnel);

  const getIconByCode = (metar) => {
    if (metar) {
      code = metar.replace(/([-+])/g, "");
      icon = WEATHER_CODES.find(element => element.code === code);    
      return icon? mapIcons.get(icon.key) : All;
    } else {
      return All;
    }
  }

  let imgWidth = {
    width: "3.5vh",
  };

  let animation = {
    animation: "19s infinite linear weather-scroll",
  }

  return (
    <div className="weather-unit">
        <div className="weather-info-left">
            <div className="iata-code">{props.weatherData.airport}</div>
            <div className="weather-icon"><img src={getIconByCode(props.weatherData.wxString)} style={imgWidth} alt="error"/></div>
        </div>
        <div className="weather-info-right">
          {props.weatherData.rawText.length > 20 ?
          <div className="weather-rawtext-scroll-container">
            <span className="weather-rawtext-str">
              <span className="weather-rawtext-str__item" style={animation}>{props.weatherData.rawText}</span>
            </span>
          </div>
          :<div className="weather-rawtext-static">{props.weatherData.rawText}</div>}
        </div>
    </div>
  );

}

export default WeatherUnit;