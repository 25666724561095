import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import './ContingencyFlightsConfig.css';
import { Prompt } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import AppsIcon from '@material-ui/icons/Apps';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import GenericSnackbar from '../../GenericSnackbar/GenericSnackbar';
import usePreventReload from '../../../hooks/usePreventReload';
import { FLEETS_LIST, TAILS_LIST, STATUS_LIST, ALERT_LIST } from '../../../utils/Constants';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';

function ContingencyFlightsConfig(props) {
    const [isDirty, setDirty, setClean] = usePreventReload();
    const [state, setState] = React.useState(props.configurationData ? props.configurationData : {
        excludedFleets: [],
        excludedTails: [],
        lastModDate: '',
        alertSort: ALERT_LIST,
        statusSort: STATUS_LIST,
    })

    const selectLabels = [{ label: 'Crew Connection', sortAtt: 'crewConnectionSort' }, { label: 'Pax Conex', sortAtt: 'paxConexSort' }];

    const handleChangeFleets = (event, value, action) => {
        setDirty();
        setState({ ...state, excludedFleets: value });
    };

    const onDragEndAlert = (result) => {
        if (!result.destination) {
            return;
        }
        const itemsAlert = Array.from(state.alertSort);
        const [reorderedItem] = itemsAlert.splice(result.source.index, 1);
        itemsAlert.splice(result.destination.index, 0, reorderedItem);
        setDirty();
        setState({ ...state, alertSort: itemsAlert });
    };
    const onDragEndStatus = (result) => {
        if (!result.destination) {
            return;
        }
        const itemsStatus = Array.from(state.statusSort);
        const [reorderedItem] = itemsStatus.splice(result.source.index, 1);
        itemsStatus.splice(result.destination.index, 0, reorderedItem);
        setDirty();
        setState({ ...state, statusSort: itemsStatus });
    };
    const handleChangeTails = (event, value, action) => {
        setDirty();
        setState({ ...state, excludedTails: value });
    };

    const handleSelectChange = (event) => {
        setDirty();
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const [snackbar, setSnackbar] = React.useState('');
    const saveChanges = () => {
        const modDate = new Date();
        state.lastModDate = modDate.toUTCString();
        props.updateConfigurationData('contingencyFlightsConfig', state).then(() => {
            setSnackbar({ msg: "Succesfully saved changes!", date: new Date(), severity: "success" });
            setState({ ...state, lastModDate: modDate });
            setClean();
        }, error => {
            console.error(error);
            setSnackbar({ msg: "Error saving changes. Try again later", date: new Date(), severity: "error" });
        });
    }

    return (
        <div className="contingency-flights">
            <div className="cf-title">
                <span>Contingency Flights</span>
            </div>

            <Tabs className="cf-tab">
                <TabList>
                    <Tab>Filters</Tab>
                    <Tab>Sort</Tab>
                </TabList>

                {/* Filters section */}
                <TabPanel>
                    <div className="desc">Select the filters to be applied in this section.</div>
                    <div className="cf-filters">
                        {/* Excluded Fleet */}
                        <div className="cf-autocomplete">
                            <div className="ac-label">Excluded Fleets</div>
                            <Autocomplete
                                multiple
                                freeSolo
                                limitTags={3}
                                id="multiple-limit-tags-fleets"
                                options={FLEETS_LIST}
                                classes={{ tag: 'ac-tag' }}
                                disableCloseOnSelect={true}
                                renderOption={(option, { selected }) => (
                                    <div className='option'>
                                        <Checkbox
                                            color="primary" classes={{ root: 'ac-checkbox' }}
                                            style={{ marginRight: '1vh' }}
                                            checked={selected}
                                            value={option}
                                        />
                                        {option}
                                    </div>
                                )}
                                value={state.excludedFleets}
                                onChange={handleChangeFleets}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Fleet" />)}
                            />
                        </div>
                        {/* Excluded Tail */}
                        <div className="cf-autocomplete">
                            <div className="ac-label">Excluded Tails</div>
                            <Autocomplete
                                multiple
                                freeSolo
                                limitTags={3}
                                id="multiple-limit-tags-tails"
                                options={TAILS_LIST}
                                classes={{ tag: 'ac-tag' }}
                                disableCloseOnSelect={true}
                                renderOption={(option, { selected }) => (
                                    <div className='option'>
                                        <Checkbox
                                            color="primary" classes={{ root: 'ac-checkbox' }}
                                            style={{ marginRight: '1vh' }}
                                            checked={selected}
                                            value={option}
                                        />
                                        {option}
                                    </div>
                                )}
                                value={state.excludedTails}
                                onChange={handleChangeTails}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Tail" />)}
                            />
                        </div>
                    </div>
                </TabPanel>
                {/* Sorts section */}
                <TabPanel>
                    <div className="desc">Order the priority of contingency flights.</div>
                    <div className="cf-sorts">
                        {/* Alert sort */}
                        <div className="cf-drag-drop-sort">
                            <div className="sort-label">1- Status Contingency</div>
                            <div className="drag-drop-body">
                                <DragDropContext onDragEnd={onDragEndAlert}>
                                    <Droppable droppableId="droppable">
                                        {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {state.alertSort.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className="drag-drop-item"
                                                            >
                                                                <div className="number">{index + 1}</div>
                                                                <div className="option">
                                                                    {item.name} <AppsIcon className="icon" />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </div>
                        {/* Status sort */}
                        <div className="cf-drag-drop-sort">
                            <div className="sort-label">2- Status</div>
                            <div className="drag-drop-body">
                                <DragDropContext onDragEnd={onDragEndStatus}>
                                    <Droppable droppableId="droppable2">
                                        {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {state.statusSort.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps} className="drag-drop-item">
                                                                <div className="number">{index + 1}</div>
                                                                <div className="option">{item.name} <AppsIcon className='icon' /></div>
                                                            </div>

                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>


                            </div>

                        </div>
                        {/* Select asc/desc sorts */}
                        {selectLabels.map((value, index) => {
                            return (
                                <div className="cf-select" key={value.label}>
                                    <FormControl variant="outlined" classes={{ root: "form-select" }}>
                                        <div className="sort-label">{index + 3}- {value.label}</div>
                                        <Select
                                            classes={{ select: 'select' }}
                                            name={value.sortAtt}
                                            inputProps={{ className: "outlined-input option" }}
                                            value={state[value.sortAtt] ? state[value.sortAtt] : ''}
                                            onChange={handleSelectChange}>
                                            <MenuItem classes={{ root: 'option' }} value="asc">Ascending</MenuItem>
                                            <MenuItem classes={{ root: 'option' }} value="desc">Descending</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>)
                        })}
                    </div>
                </TabPanel>
            </Tabs>

            {/* Buttons section */}
            <div className="cf-buttons">
                <div className="last-mod">
                    Last modification made: {state.lastModDate ? <Moment format="DD/MM/yyyy HH:mm" tz="UTC">{state.lastModDate}</Moment> : null}
                </div>
                <Button variant="contained" classes={{ root: 'vw-button' }} onClick={saveChanges} disableElevation>
                    Apply changes to video wall
                </Button>
            </div>

            {/* Generic Snackbar */}
            {snackbar ? <GenericSnackbar key={snackbar.date} severity={snackbar.severity} message={snackbar.msg} /> : null}
            {/* Custom Warning Prompt */}
            <Prompt when={isDirty} message='Custom prompt' />
        </div >
    );

}

export default ContingencyFlightsConfig;