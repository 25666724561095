import React from 'react';
import Flight from './Flight/Flight';
import './FlightGroup.css';

function FlightGroup(props){
  
  return(
    <div className="mySlides fade">
      {
        props.flightGrp.flights.map((flight, indx) => (
          <Flight  key={indx} {...{ flight }} />
        ))
      }
    </div>
  );
}

export default FlightGroup;