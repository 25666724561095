import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import './WeatherConfig.css';
import usePreventReload from '../../../hooks/usePreventReload';
import { Prompt } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import GenericSnackbar from '../../GenericSnackbar/GenericSnackbar';
import { BR_AIRPORTS,WEATHER_GROUPS_LP, SSC_AIRPORTS, WEATHER_GROUPS_INTER,WEATHER_GROUPS_INTBR,WEATHER_GROUPS_4C,WEATHER_GROUPS_4M,WEATHER_GROUPS_ALT,WEATHER_GROUPS_CL,WEATHER_GROUPS_JJ, WEATHER_GROUPS_SSC, WEATHER_GROUPS_XL  } from '../../../utils/Constants';


function WeatherConfig(props) {

    const wheatherFilters = {  
        "SSC":SSC_AIRPORTS,
        "BR":BR_AIRPORTS,
        WEATHER_GROUPS_INTER,WEATHER_GROUPS_INTBR,
        WEATHER_GROUPS_4C,
        WEATHER_GROUPS_4M,
        WEATHER_GROUPS_ALT,
        WEATHER_GROUPS_CL,
        WEATHER_GROUPS_JJ,
        WEATHER_GROUPS_SSC, 
        WEATHER_GROUPS_XL,
        WEATHER_GROUPS_LP};
    const wheatherFiltersNames =  [
            "SSC",
            "BR",
            "WEATHER_GROUPS_INTER",
            "WEATHER_GROUPS_INTBR",
            "WEATHER_GROUPS_4C",
            "WEATHER_GROUPS_4M",
            "WEATHER_GROUPS_ALT",
            "WEATHER_GROUPS_CL",
            "WEATHER_GROUPS_JJ",
            "WEATHER_GROUPS_SSC", 
            "WEATHER_GROUPS_XL",
            "WEATHER_GROUPS_LP"];

    const [isDirty, setDirty, setClean] = usePreventReload();
    const [state, setState] = React.useState(props.configurationData ? props.configurationData : {
        airportsFilter: []
    })

    const [snackbar, setSnackbar] = React.useState();
    const saveChanges = () => {
        const modDate = new Date();
        state.lastModDate = modDate.toUTCString();
        props.updateConfigurationData('weatherConfig', state).then(() => {
            setSnackbar({ msg: "Succesfully saved changes!", date: new Date(), severity: "success" });
            setState({ ...state, lastModDate: modDate });
            setClean();
        }, error => {
            console.error(error);
            setSnackbar({ msg: "Error saving changes. Try again later", date: new Date(), severity: "error" });
        });
    }

    const handleSelectChange = (event) => {
        setDirty();
        let index;
        let filters = event.target.value;
        wheatherFiltersNames.forEach(filtro=>{
            if(filters.includes(filtro)){
                if (isAllSelected(filtro)) {
                    wheatherFilters[filtro].forEach(a => {
                        index = filters.indexOf(a);
                        filters.splice(index, 1);
                    })
                } else {
                    wheatherFilters[filtro].forEach(a => {
                        index = filters.indexOf(a);
                        if (index === -1) {
                            filters.push(a);
                        }
                    })
                }
                index = filters.indexOf(filtro);
                filters.splice(index, 1);
            }
        })
        
        setState({ ...state, airportsFilter: filters });
    }

    const isAllSelected = (country) => {
        let isAllSelected = true;
 
            wheatherFilters[country].forEach(a => {
                if (state.airportsFilter.indexOf(a) === -1) {
                    isAllSelected = false;
                }
            })
        
        return isAllSelected;
    }

    return (
        <div className="weather-config">
            <div className="w-title">
                <span>Weather</span>
            </div>
            <Tabs className="w-tab">
                <TabList>
                    <Tab>Filters</Tab>
                </TabList>

                {/* Filters section */}
                <TabPanel>
                    <div className="desc">Select what you want to see on Weather.</div>

                    <div className="w-filters">
                        <FormControl variant="outlined" classes={{ root: "w-select" }}>
                            <TextField
                                select
                                label="Select Airport"
                                onChange={handleSelectChange}
                                variant="outlined"
                                SelectProps={{
                                    multiple: true,
                                    value: state.airportsFilter,
                                    renderValue: (value) => value.join(', ')
                                }}
                            >
                                <MenuItem classes={{ root: 'option-all' }} value="BR">
                                    <Checkbox color="primary" checked={isAllSelected("BR")} />
                                    BR
                                </MenuItem>
                                {BR_AIRPORTS.map((arprt) => (
                                    <MenuItem classes={{ root: 'w-option' }} key={arprt} value={arprt}>
                                        <Checkbox color="primary" checked={state.airportsFilter.indexOf(arprt) > -1} />
                                        {arprt}
                                    </MenuItem>
                                ))}
                                <MenuItem classes={{ root: 'option-all' }} value="SSC">
                                    <Checkbox color="primary" checked={isAllSelected("SSC")} />
                                    SSC
                                </MenuItem>
                                {SSC_AIRPORTS.map((arprt) => (
                                    <MenuItem classes={{ root: 'w-option' }} key={arprt} value={arprt}>
                                        <Checkbox color="primary" checked={state.airportsFilter.indexOf(arprt) > -1} />
                                        {arprt}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </div>
                    <div className="desc"></div>

                    <div className="w-filters">
                        <FormControl variant="outlined" classes={{ root: "w-select" }}>
                            <TextField
                                select
                                label="Select Filter"
                                onChange={handleSelectChange}
                                variant="outlined"
                                SelectProps={{
                                    multiple: true,
                                    value: state.airportsFilter,
                                    renderValue: (value) => value.join(', ')
                                }}
                            >
                                <MenuItem classes={{ root: 'option-all' }} value="WEATHER_GROUPS_INTER">
                                    <Checkbox color="primary" checked={isAllSelected("WEATHER_GROUPS_INTER")} />
                                    INTER SSC
                                </MenuItem>
                                <MenuItem classes={{ root: 'option-all' }} value="WEATHER_GROUPS_INTBR">
                                    <Checkbox color="primary" checked={isAllSelected("WEATHER_GROUPS_INTBR")} />
                                    INTER BR
                                </MenuItem>
                                <MenuItem classes={{ root: 'option-all' }} value="WEATHER_GROUPS_4C">
                                    <Checkbox color="primary" checked={isAllSelected("WEATHER_GROUPS_4C")} />
                                    COLOMBIA
                                </MenuItem>
                                <MenuItem classes={{ root: 'option-all' }} value="WEATHER_GROUPS_4M">
                                    <Checkbox color="primary" checked={isAllSelected("WEATHER_GROUPS_4M")} />
                                    ARGENTINA
                                </MenuItem>
                                <MenuItem classes={{ root: 'option-all' }} value="WEATHER_GROUPS_ALT">
                                    <Checkbox color="primary" checked={isAllSelected("WEATHER_GROUPS_ALT")} />
                                    AEROPUERTOS DE ALTERNATIVA
                                </MenuItem>
                                <MenuItem classes={{ root: 'option-all' }} value="WEATHER_GROUPS_CL">
                                    <Checkbox color="primary" checked={isAllSelected("WEATHER_GROUPS_CL")} />
                                    CHILE
                                </MenuItem>
                                <MenuItem classes={{ root: 'option-all' }} value="WEATHER_GROUPS_JJ">
                                    <Checkbox color="primary" checked={isAllSelected("WEATHER_GROUPS_JJ")} />
                                    BRASIL
                                </MenuItem>
                                <MenuItem classes={{ root: 'option-all' }} value="WEATHER_GROUPS_SSC">
                                    <Checkbox color="primary" checked={isAllSelected("WEATHER_GROUPS_SSC")} />
                                    SSC
                                </MenuItem>
                                <MenuItem classes={{ root: 'option-all' }} value="WEATHER_GROUPS_XL">
                                    <Checkbox color="primary" checked={isAllSelected("WEATHER_GROUPS_XL")} />
                                    ECUADOR
                                </MenuItem>
                                <MenuItem classes={{ root: 'option-all' }} value="WEATHER_GROUPS_LP">
                                    <Checkbox color="primary" checked={isAllSelected("WEATHER_GROUPS_LP")} />
                                    PERU
                                </MenuItem>
                                
                            </TextField>
                        </FormControl>
                    </div>
                </TabPanel>
            </Tabs>

            {/* Buttons section */}
            <div className="w-buttons">
                <div className="last-mod">
                    Last modification made: {state.lastModDate ? <Moment format="DD/MM/yyyy HH:mm" tz="UTC">{state.lastModDate}</Moment> : null}
                </div>
                <Button variant="contained" classes={{ root: 'vw-button' }} onClick={saveChanges} disableElevation>
                    Apply changes to video wall
                </Button>
            </div>

            {/* Generic Snackbar */}
            {snackbar ? <GenericSnackbar key={snackbar.date} severity={snackbar.severity} message={snackbar.msg} /> : null}
            {/* Custom Warning Prompt */}
            <Prompt when={isDirty} message='Custom prompt' />
        </div>
    );
}


export default WeatherConfig;