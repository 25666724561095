import React, { useEffect, useState } from 'react';
import GenericTitle from '../GenericTitle/GenericTitle';
import Loader from '../Loader/Loader';
import OTPService from '../../service/OTPService';
import { streamOTP } from '../../firebase';
import Punctuality from './Punctuality/Punctuality';
import LatamImg from './latam-otp.png';
import AzulImg from './azul-otp.png';
import GolImg from './gol-otp.png';
import './Otp.css';

let isLoading = true;
let dailyPunctualityListSplit = [];
let delayReasonList = [];
let lastUpdate, latamGoal, dailyPunctualityList;
const otpService = new OTPService();
let percents = { gol: "", azul: "", latam: ""};

function Otp(props) {
  const userCCO = props.userCCO;
  const [state, setState] = useState({dailyPunctualityList: []});

  useEffect(() => {   
    const unsubscribe = streamOTP({
      next: (querySnapshot) => {
        dailyPunctualityList = [];
        delayReasonList = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().lastUpdate) {
            lastUpdate = doc.data().lastUpdate;
          }
          if (doc.id === 'otpPunctuality' || doc.id === 'otpPunctualityHub'|| doc.id === 'otpPunctualityLATAM') {
            dailyPunctualityList = dailyPunctualityList.concat(doc.data().data ? doc.data().data : []);
          }
          if (doc.id === 'otpDelayReason' || doc.id === 'otpDelayReasonHub'|| doc.id === 'otpDelayReasonsLATAM') {
            delayReasonList = delayReasonList.concat(doc.data().data ? doc.data().data : []);
          }
          if(doc.data()?.rankingBR) {
            percents = doc.data().rankingBR;
          }
          if (doc.id === 'otpGoals') {
            latamGoal = doc.data().data ? doc.data().data : null;
          }
        });
        isLoading = false;
        setState({ dailyPunctualityList: dailyPunctualityList, lastUpdate: lastUpdate });
      },
      error: (e) => {
        console.error(e);
        isLoading = false;
      }
    });
    return unsubscribe;
  }, []);

  state.dailyPunctualityList.forEach(otp => {
    otp.delayReason = delayReasonList.filter(e => {
      const dlGroup = e.businessGroup? e.businessGroup : e.businessGroupHub? e.businessGroupHub : "LATAM TOTAL";
      const otpGroup = otp.businessGroup? otp.businessGroup : otp.businessGroupHub? otp.businessGroupHub : "LATAM TOTAL";
      return otpGroup === dlGroup;
    });
    otp.delayReason.sort((a,b) => Number(b.SSC_countDelayReason) - Number(a.SSC_countDelayReason));
  });
  dailyPunctualityListSplit = otpService.applyConfigurationAndSplit(state.dailyPunctualityList, props);

  return (
    <div className="otp">
      {!isLoading && (
      <>
        <div className="otp-header">
          <GenericTitle title={"OTP"} color={"#775DD3"} lastUpdate={state.lastUpdate}/>
          {userCCO === 'BR' &&
          <>
            <div className="otp-head-label">
              <p className="label-p1">STD 30´</p>
              <p className="label-p2">TODAY</p>
            </div>
            <div className="otp-airlines-percents">
              <div className="otp-latam">
                <img src={LatamImg} alt="error"/>
                <p className="otp-air-pcnts">{displayPercents(percents.latam)}</p>
              </div>
              <div className="otp-azul">
                <img src={AzulImg} alt="error"/>
                <p className="otp-air-pcnts">{displayPercents(percents.azul)}</p>
              </div>
              <div className="otp-gol">
                <img src={GolImg} alt="error"/>
                <p className="otp-air-pcnts">{displayPercents(percents.gol)}</p>
              </div>
            </div>
          </>}
        </div>
        <Punctuality dailyPunctualityList={dailyPunctualityListSplit} latamGoal={latamGoal}/>
      </>
      )}
      {/* Loader */ isLoading && (<> <Loader id='otp-loader'/> </> )}
    </div>
  );
}
function displayPercents(percents){
  return parseInt(percents)?parseInt(percents)+"%":"-";
  
}
export default Otp;
