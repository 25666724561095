import React from 'react';
import './GenericTitle.css';

let hour, minutes, date;

function GenericTitle(props) {

  const style = {
    borderColor: props.color,
  }
  if (props.lastUpdate) {
    date = new Date(props.lastUpdate);
    hour = date.getUTCHours();
    minutes =  date.getUTCMinutes() < 10? '0'+date.getUTCMinutes() : date.getUTCMinutes();
  }

  if(props.margin !== undefined)
    style.margin = props.margin

  const subtitleStyle = {
    display: 'inline-block',
    backgroundColor: props.color
  };

  if(props.subTitle === undefined)
    subtitleStyle.display = 'none';
    

  return (
    <div className="generic-title" style={style}>
      <p>{props.title} { props.subTitle ? <span style={subtitleStyle}>{ props.subTitle }</span> : null} </p>
      <div className="generic-update-labels">
        <p>Last update:</p>
        <p>{props.lastUpdate? hour+':'+minutes : "--:--"}</p>
      </div>
    </div>
    );
}

export default GenericTitle;