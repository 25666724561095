import React from 'react';
import './Punctuality.css';
import Carousel from 'react-material-ui-carousel'
import PercentBox from '../PercentBox/PercentBox';
import PercentBoxDep0 from '../PercentBoxDep0/PercentBoxDep0';
import OTPService from '../../../service/OTPService';
import LatamLogoOtp from '../../../vector/latam-logo-otp.svg';
import {PARAM_GOAL} from '../../../utils/Constants';

const otpService = new OTPService();

function getGoal(otpGoals,businessGoal){
  var goal;
    otpGoals?.forEach(element => {
      if(element.businessDetailGoal === PARAM_GOAL[businessGoal]){
        goal = element;
      }
    });
  return goal;
}

function Punctuality(props) {
  const getBusinessGroup = (data) => {
    return data.businessGroup ? data.businessGroup : data.businessGroupHub;
  }

  return(
    <div className="punctuality">
      <p className="punctuality-title">&emsp;DAILY PUNCTUALITY</p>


      <Carousel navButtonsAlwaysInvisible={true} interval={36000} swipe={false}>
      {/* foreach */}
      {
        props.dailyPunctualityList.map((myData, indx) => {
          return (
          <div key={indx} className="punctuality-unit">
            <p className="punct-titles-afected"><span >AFFECTED FLIGHTS</span></p>
            <p className="punct-titles"><span>DEP 0'</span><span>ARR 14</span><span >IMP 60</span><span className ="punct-titles-cnl" >CNL</span><span>LEGS</span><span>DELAY CODE</span></p>
            <div className="punct-unit-table">              

            {myData.map( (itemData, index) =>
            <div key={index} className="punct-row">
              <span className="punct-cell1">&emsp;{getBusinessGroup(itemData)==="ATAM TOTAL"? <img className="latam-logo" src={LatamLogoOtp} alt="error"></img> : getBusinessGroup(itemData)}</span>
              <div className="punct-cell2"><PercentBoxDep0 value={otpService.getDataByBusinessGroup(itemData, 'std0Today')} goal={getGoal(props.latamGoal,getBusinessGroup(itemData))?.monthGoalStd0} type="dep"/></div>
              <span className="punct-cell3">{otpService.getDataByBusinessGroup(itemData, 'arr14Today')}</span>
              <div className="veo-punct-cell4 cell4-puntuality cell4-left" style={{width: "12.5%"}}>
                <PercentBox value={otpService.getDataByBusinessGroup(itemData, 'imp60Daily')} goal={getGoal(props.latamGoal,getBusinessGroup(itemData))?.monthGoalImpact60} type={"affected"} isVeo/>
              </div>
              <div className="veo-punct-cell4 cell4-puntuality" style={{width: "12.5%"}}>
                <PercentBox value={otpService.getDataByBusinessGroup(itemData, 'cnlToday')} goal={getGoal(props.latamGoal,getBusinessGroup(itemData))?.monthGoalCNL} type={"affected"} isVeo/>
              </div>
              <span className="punct-cell5">{otpService.getDataByBusinessGroup(itemData, 'legs').replace(',','')}/{otpService.getDataByBusinessGroup(itemData, 'totalLegs').replace(',','')}</span>
              <div className="punct-delays-cell">

                {
                  Array.from(Array(3).keys()).map((value, index) =>{
                    let validDelayReason = itemData.delayReason && itemData.delayReason[index];
                    return ( <div key={index} className="delay-unit">
                      <p>{validDelayReason ? itemData.delayReason[index].delayCode : null}</p>
                      <p>{validDelayReason ? otpService.getDataByBusinessGroup(itemData.delayReason[index], 'countDelayReason') : '-'}</p>
                    </div>)
                  })
                }
              </div>
            </div>
           )}
              

            </div>
          </div>
        )})
        }
      
      </Carousel>
    </div>
  );
}

export default Punctuality;