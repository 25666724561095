import Button from "@material-ui/core/Button";
import React from "react";
import Moment from "react-moment";
import { updateSummaryLimopsGoals } from "../../../../firebase";
import maintainerBR from "../../../../vector/br.png";
import GenericSnackbar from "../../../GenericSnackbar/GenericSnackbar";
import "./CrewStandbyMaintainerBrasil.css";
function CrewStandbyMaintainerBrasil(props) {
  const [snackbar, setSnackbar] = React.useState("");

  const saveChanges = () => {
    const newGoals = { ...props.crewStandby }; // Crear una copia del estado actual
    const modDate = new Date();
    updateSummaryLimopsGoals({
      data: newGoals,
      lastUpdate: modDate.toISOString(),
    }).then(
      () => {
        setSnackbar({
          msg: "Succesfully saved changes!",
          date: new Date(),
          severity: "success",
        });
      },
      (error) => {
        console.error(error);
        setSnackbar({
          msg: "Error saving changes. Try again later",
          date: new Date(),
          severity: "error",
        });
      }
    );
  };

  return (
    <div className="pulento">
      <div className="crew-standby-main">
        <div style={{ height: "100%", padding: ".5vh", display: "none" }}>
          <div className="csb-header">
            <div className="">Para Turnos Casa:</div>
            <div className="br-head">
              00:00 <br /> 03:00
            </div>
            <div className="br-head">
              03:00 <br /> 06:00
            </div>
            <div className="br-head">
              06:00 <br /> 09:00
            </div>
            <div className="br-head">
              09:00 <br /> 12:00
            </div>
            <div className="br-head">
              12:00 <br /> 15:00
            </div>
            <div className="br-head">
              15:00 <br /> 18:00
            </div>
            <div className="br-head">
              18:00 <br /> 21:00
            </div>
            <div className="br-head">
              21:00 <br /> 00:00
            </div>
          </div>

          <div className="csb-body brasil-table">
            <div className=""></div>
            <div className="AM">
              <p>M</p>
              <p>C</p>
            </div>
            <div className="PM">
              <p>M</p>
              <p>C</p>
            </div>
            <div className="ATO">
              <p>M</p>
              <p>C</p>
            </div>
            <div className="T24">
              <p>M</p>
              <p>C</p>
            </div>
            <div className="AM">
              <p>M</p>
              <p>C</p>
            </div>
            <div className="PM">
              <p>M</p>
              <p>C</p>
            </div>
            <div className="ATO">
              <p>M</p>
              <p>C</p>
            </div>
            <div className="T24">
              <p>M</p>
              <p>C</p>
            </div>
          </div>

          <div className="csb-color brasil-content">
            <div className=""></div>
            <div className="AM">
              <p className="semaforo yelloww">ATO</p>
              <p className="semaforo redd">CASA</p>
            </div>
            <div className="PM">
              <p className="semaforo yelloww">ATO</p>
              <p className="semaforo redd">CASA</p>
            </div>
            <div className="ATO">
              <p className="semaforo yelloww">ATO</p>
              <p className="semaforo redd">CASA</p>
            </div>
            <div className="T24">
              <p className="semaforo yelloww">ATO</p>
              <p className="semaforo redd">CASA</p>
            </div>
            <div className="AM">
              <p className="semaforo yelloww">ATO</p>
              <p className="semaforo redd">CASA</p>
            </div>
            <div className="PM">
              <p className="semaforo yelloww">ATO</p>
              <p className="semaforo redd">CASA</p>
            </div>
            <div className="ATO">
              <p className="semaforo yelloww">ATO</p>
              <p className="semaforo redd">CASA</p>
            </div>
            <div className="T24">
              <p className="semaforo yelloww">ATO</p>
              <p className="semaforo redd">CASA</p>
            </div>
          </div>

          {props.crewStandby?.map((ct, index) => (
            <div className="csb-color brasil-content" key={index}>
              <div className="">{ct.companyCode}</div>{" "}
              {/* Accede a una propiedad específica */}
              <div className="AM">
                <p className="semaforo">1/2</p>
                <p className="semaforo">2/3</p>
              </div>
              <div className="PM">
                <p className="semaforo">3/4</p>
                <p className="semaforo">4/3</p>
              </div>
              <div className="ATO">
                <p className="semaforo">5/4</p>
                <p className="semaforo">4/5</p>
              </div>
              <div className="T24">
                <p className="semaforo">6/3</p>
                <p className="semaforo">2/5</p>
              </div>
              <div className="AM">
                <p className="semaforo">6/3</p>
                <p className="semaforo">2/5</p>
              </div>
              <div className="PM">
                <p className="semaforo">6/3</p>
                <p className="semaforo">2/5</p>
              </div>
              <div className="ATO">
                <p className="semaforo">6/3</p>
                <p className="semaforo">2/5</p>
              </div>
              <div className="T24">
                <p className="semaforo">6/3</p>
                <p className="semaforo">2/5</p>
              </div>
            </div>
          ))}
        </div>

        <img src={maintainerBR} alt="" />
      </div>

      <div className="cf-buttons">
        <div className="last-mod">
          Last modification made:{" "}
          {props.lastState ? (
            <Moment format="DD/MM/yyyy HH:mm" tz="UTC">
              {props.lastState}
            </Moment>
          ) : null}
        </div>
        <Button
          variant="contained"
          classes={{ root: "vw-button" }}
          onClick={saveChanges}
          disableElevation
        >
          Apply changes to video wall
        </Button>
      </div>
      {/* Generic Snackbar */}
      {snackbar ? (
        <GenericSnackbar
          key={snackbar.date}
          severity={snackbar.severity}
          message={snackbar.msg}
        />
      ) : null}
    </div>
  );
}

export default CrewStandbyMaintainerBrasil;
