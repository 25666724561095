import React from 'react';
import './CriticalAircraftTable.css';
import './CriticalAircraftTableScroll.scss';
import Moment from 'react-moment';

function CriticalAircraftTable(props) {

  let colorByStat = {
    color: "#FF7D7D",
  };

  let animatedText = {
    animation: "ticker linear 15s infinite",
  }

  let staticText = {
    animation: "linear 15s infinite",
  }

  if (props.flightData.aircftStatus === "CONT")
    colorByStat.color = "#FF8A00";
  else if (props.flightData.aircftStatus === "RISK")
    colorByStat.color = "#FFC700";

  let [overflowState, setOverflowState] = React.useState(false);

  React.useLayoutEffect(() => {

    let reasonText = document.getElementsByClassName("reasonText");

    for (let i = 0; i < reasonText.length; i++) {
      if (props.flightData.reason === reasonText[i].innerHTML) {
        setOverflowState(reasonText[i].scrollWidth > reasonText[i].offsetWidth);
        break;
      }
    }

  }, [props.flightData.reason]);

  return (
    <div className="ca-row">
      <div className="ca-row-top">
        <div className="ca-col">
          <div className="ca-left-cell ca-black-italic">{props.flightData.base}</div>
          <div className="ca-middle-cell">{props.flightData.tail}</div>
          <div className="ca-right-cell ca-black-italic" style={colorByStat}>{props.flightData.aircftStatus}</div>
        </div>
        <div className="ca-col ca-border-left">
          <div className="ca-cell-inline-2 ca-zcool"><Moment format="HH:mm">{props.flightData.reportTime}</Moment></div>
          <div className="ca-cell-inline-2"><Moment format="DD MMM">{props.flightData.reportTime}</Moment></div>
        </div>
        <div className="ca-col ca-border-left">
          <div className="ca-cell-inline-2" style={colorByStat}>
            {props.flightData.maintStatus}&nbsp;
            {props.flightData.maintStatusTime && props.flightData.aircftStatus === 'AOG' ?
              <Moment className="ca-zcool" format="HH:mm" tz="UTC">{props.flightData.maintStatusTime}</Moment> :
              <Moment className="ca-zcool" format="HH:mm">{props.flightData.maintStatusTime}</Moment>
            }
          </div>
          <div className="ca-cell-inline-2"><Moment format="DD MMM">{props.flightData.maintStatusTime}</Moment></div>
        </div>
      </div>
      <div className="ca-row-bottom">
        <div className="ca-fleet-container">
          {props.flightData.fleet.substring(0, 4)} {props.flightData.carga ? '- F' : ''}
        </div>
        <div className="ca-reason-container">
          <div className="reasonText ca-reason" style={overflowState ? animatedText : staticText}>
            {props.flightData.reason}
          </div>
        </div>
      </div>
    </div>
  );

}

export default CriticalAircraftTable;
