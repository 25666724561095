import React, {useContext} from 'react';
import Button from '@material-ui/core/Button';
import logoAirlines from './logo-airlines.svg';
import './Login.css';
import GenericSnackbar from '../GenericSnackbar/GenericSnackbar';
import Loader from '../Loader/Loader';
import {AuthContext} from '../../providers/AuthProvider';
import {auth, googleAuthProvider, localPersistence} from '../../firebase';

function Login() {
  const authState = useContext(AuthContext);

  const signInWithGoogle = () => {
    auth.setPersistence(localPersistence)
      .then(() => {
        // Existing and future Auth states are now persisted even when the browser window is
        // closed or the activity is destroyed in react-native. Only signing out will clear Auth state.
        auth.signInWithPopup(googleAuthProvider).then(() => {
          // User validation in src/providers/AuthProvider.js and redirect in App.js
        }).catch((error) => {
          console.error(error);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return(
    <div className="LoginComp">
      <div className="LoginBox">
        <img src={logoAirlines} alt="missing"></img>
        {!authState.loading && !authState.initLoad &&
          <div>
            <Button className="LoginButton" variant="contained" color="primary" onClick={signInWithGoogle}>SIGN IN</Button>
          </div>
        }
        {/* Blue Loader */ (authState.loading || authState.initLoad) && (<> <Loader id='login-loader' blue/> </> )}
      </div>

      {/* Unauthorized Snackbar */}
      {authState.unauthorized ? <GenericSnackbar key={new Date()} severity={'error'} message={'ERROR! You are not authorized to use this application'}/> : null}
    </div>
  );
  
}

export default Login;