import React from 'react';
import './GenericSnackbar.css'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export default function GenericSnackbar(props) {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert severity={props.severity} elevation={6} variant="filled" classes={{root: 'vw-alert'}}>
                {props.message}
            </Alert>
        </Snackbar>
    );
}