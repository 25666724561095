import React from "react";
import "./CrewStandbyGroupSSC.css";

function CrewStandbyGroupSSC({ ctalFlightGrp, isBrasil }) {


  //Aqui se agrupan los datos para ambos flujos, subFleetCode y baseCode (Brasil), companyCode (SSC)
  const groupedData = ctalFlightGrp.reduce((acc, item) => {
    const key = `${item.subFleetCode}${item.baseCode}${item.companyCode}`;
    if (!acc[key]) {
      acc[key] = { ...item, ranges: {} };
    }
    acc[key].ranges[item.timeRange] = {
      MANDO: item.MANDO,
      CABINA: item.CABINA,
    };
    return acc;
  }, {});

  // En el renderizado:
  return (
    <div className={"mySlides3 cf-fade crew"}>
      {Object.values(groupedData).map((ct) => {
        const combinedCode = `${ct.subFleetCode}${ct.baseCode || ""}${
          ct.companyCode ? ct.companyCode.substring(0, 2) : ""
        }`;
   
          // Renderizado para SSC (no Brasil)
          return (
            <div
              className="summary-limops-tittle summary-limops-row-style"
              key={ct.idx}
            >
              <div className="summary-limops-colx2 ssc">{combinedCode}</div>
              <div
                className={
                  ct.mandoAm > 0
                    ? "summary-limops-col white"
                    : "summary-limops-col red"
                }
              >
                {ct.mandoAm || 0}
              </div>
              <div
                className={
                  ct.cabinaAm > 0
                    ? "summary-limops-col white"
                    : "summary-limops-col red"
                }
              >
                {ct.cabinaAm || 0}
              </div>
              <div
                className={
                  ct.mandoPm > 0
                    ? "summary-limops-col white"
                    : "summary-limops-col red"
                }
              >
                {ct.mandoPm || 0}
              </div>
              <div
                className={
                  ct.cabinaPm > 0
                    ? "summary-limops-col white"
                    : "summary-limops-col red"
                }
              >
                {ct.cabinaPm || 0}
              </div>
              {console.log(ct.cabinCrewDistribution)}
              <div
                className={
                  getATOColor(ct.cabinCrewDistribution)
                    ? "summary-limops-col ato white"
                    : "summary-limops-col ato red"
                }
              >
                {getATO(ct.cabinCrewDistribution,combinedCode)}
              </div>
              <div
                className={
                  getATOColor(ct.crewDistribution)
                    ? "summary-limops-col ato white"
                    : "summary-limops-col ato red"
                }
              >
                {getATO(ct.crewDistribution,combinedCode)}
              </div>
              <div
                className={
                  ct.mandoT24 > 0
                    ? "summary-limops-col white t24"
                    : "summary-limops-col red t24"
                }
              >
                {getT24(ct.mandoT24,combinedCode)}
              </div>
              <div
                className={
                  ct.cabinaT24 > 0
                    ? "summary-limops-col white t24"
                    : "summary-limops-col red t24"
                }
              >
                {getT24(ct.cabinaT24,combinedCode)}
              </div>
            </div>
          );
        
      })}
    </div>
  );
}
function getT24(numero, key) {
  if (key.includes('PE')) {
      return numero || 0;
  } else {
      return '-';
  }
}
function getATO(numero, key) {
  let withoutAto  = ["777BR","787BR","320EC"];
  if (withoutAto.includes(key)) {
      return "-"
  } else {
      return numero || 0;
  }
}
function getATOColor(ato){
  return /[1-9]/.test(ato);
}
export default CrewStandbyGroupSSC;
