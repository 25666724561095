import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import './DataFilters.css';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import GenericSnackbar from '../../GenericSnackbar/GenericSnackbar';
import usePreventReload from '../../../hooks/usePreventReload';
import {Prompt} from 'react-router-dom';

const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& svg': {
        width: '2.3vh',
        height: 'auto'
      },
    } 
})

function DataFilters(props) {
    const classes = useStyles();
    const [isDirty, setDirty, setClean] = usePreventReload();
    const dataFiltersConfig = props.configurationData;
    const filials = ['LA', 'LP', '4C', 'JJ', 'PZ', 'XL', 'LU'];
    const [state, setState] = React.useState(dataFiltersConfig? dataFiltersConfig : {
        filialOpFilter: [],
        lastModDate: ''
    })

    const isAllSelected = (operator) => { 
        let allSelected = true;
        filials.forEach(f => {
            if (state.filialOpFilter.indexOf(f + ' ' + operator) === -1) {
                allSelected = false;
            }
        })
        return allSelected;
    }

    const handleChange = (event) => {
        setDirty();
        const index = state.filialOpFilter.indexOf(event.target.value)
        if (index > -1) {
            state.filialOpFilter.splice(index, 1)
        } else {
            state.filialOpFilter.push(event.target.value)
        }
        setState({ ...state, filialOpFilter: state.filialOpFilter });
    };

    const handleChangeAll = (event) => {
        setDirty();
        filials.forEach(f => {
            const index = state.filialOpFilter.indexOf(f + ' ' + event.target.value);
            if (index === -1 && event.target.checked) {
                state.filialOpFilter.push(f + ' ' + event.target.value)
            } else if (!event.target.checked) {
                state.filialOpFilter.splice(index, 1)
            }
        })
        setState({ ...state, filialOpFilter: state.filialOpFilter });
    };

    const [snackbar, setSnackbar] = React.useState('');

    const saveChanges = () => {        
        const modDate = new Date();
        state.lastModDate = modDate.toUTCString();
        props.updateConfigurationData('dataFiltersConfig', state).then(() =>{
            setSnackbar({ msg: "Succesfully saved changes!", date: new Date(), severity: "success" });
            setState({ ...state, lastModDate: modDate });
            setClean();            
        }, error => {
            console.error(error);
            setSnackbar({ msg: "Error saving changes. Try again later", date: new Date(), severity: "error" });
        });
    }

    return (
        <div className="data-filters">
            <div className="df-title">
                <span>General data filters</span>
            </div>
            {/* Select filiales Operators section */}       
            <div className="df-config">
                <div className="df-select-filiales">
                    <p className="desc">Selection of subsidiaries and operators that will be visible throughout the Video Wall.</p>  
                    <div className="form"> 
                        <FormGroup row classes={{root: 'fg-row'}}>                            
                            <div className="filial">
                                <p>ALL</p>
                            </div>
                            <FormControlLabel
                                control={<Checkbox color="primary" className={classes.root} checked={isAllSelected("DOM")} onChange={handleChangeAll} value="DOM" />}
                                label={<Typography classes={{root: 'cb-typo'}}>DOM</Typography>}
                                classes={{root: 'cb-label'}}
                            />
                            <FormControlLabel
                                control={<Checkbox color="primary" className={classes.root} checked={isAllSelected("INT")} onChange={handleChangeAll} value="INT" />}
                                label={<Typography classes={{root: 'cb-typo'}}>INT</Typography>}
                                classes={{root: 'cb-label'}}
                            />
                        </FormGroup>   
                        <Divider />        
                        {filials.map((filial) => {
                            return(
                            <FormGroup row key={filial} classes={{root: 'fg-row'}}>                            
                                <div className="filial">
                                    <p>{filial}</p>
                                </div>
                                <FormControlLabel
                                    control={<Checkbox color="primary" className={classes.root} name={filial} checked={state.filialOpFilter.includes(filial + ' DOM')} onChange={handleChange} value={filial + ' DOM'} />}
                                    label={<Typography classes={{root: 'cb-typo'}}>DOM</Typography>}
                                    classes={{root: 'cb-label'}}
                                />
                                <FormControlLabel
                                    control={<Checkbox color="primary" className={classes.root} name={filial} checked={state.filialOpFilter.includes(filial + ' INT')} onChange={handleChange} value={filial + ' INT'} />}
                                    label={<Typography classes={{root: 'cb-typo'}}>INT</Typography>}
                                    classes={{root: 'cb-label'}}
                                />
                            </FormGroup>)
                        })}  
                    </div>                      
                </div>
            </div>

            {/* Buttons section */}
            <div className="df-buttons">
                <div className="last-mod">                   
                    Last modification made: {state.lastModDate ? <Moment format="DD/MM/yyyy HH:mm" tz="UTC">{state.lastModDate}</Moment> : null}
                </div>
                <Button variant="contained" classes={{root: 'vw-button'}} onClick={saveChanges} disableElevation>
                    Apply changes to video wall
                </Button>
            </div>

            {/* Generic Snackbar */}
            {snackbar ? <GenericSnackbar key={snackbar.date} severity={snackbar.severity} message={snackbar.msg}/> : null}
            {/* Custom Warning Prompt */}
            <Prompt when={isDirty} message='Custom prompt' />
        </div>      
    );
    
}

export default DataFilters;