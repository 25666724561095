import { FILIAL_GROUPS, FLIGHT_ALERT_LIST } from '../utils/Constants';
import moment from 'moment';

class CriticalFlightsService {

  /**
   * Apply user configuration and group critical flights by operator
   * @param {array} flightsList 
   * @param {object} config 
   */
  applyConfigAndGroupByOperator(flightsList, config) {
    const filteredFlights = this.filterCriticalFlights(flightsList, config);
    filteredFlights.sort((a, b) => moment.utc(a.dprtrDateTime, 'DD/MM/YYYY HH:mm:ss').diff(moment.utc(b.dprtrDateTime, 'DD/MM/YYYY HH:mm:ss')));
    let result = [];
    filteredFlights.forEach(element => {
      const flightGroup = result.find(fg => fg.type === element.operatorType && fg.flights.length < 14);
      if (flightGroup) {
        flightGroup.flights.push(element);
      } else {
        const obj = { type: element.operatorType, flights: [] };
        obj.flights.push(element);
        result.push(obj);
      }
    });
    return result;
  }
  /*
  organiza los vuelos por pestaña de carrusel 
  */
  groupByDots(criticalFightsGroupList) {
    let result = [];
    var flightGroupA = [];
    var obj;
    var count = 0;
    var largo = 0;

    criticalFightsGroupList.forEach(flightsGroup => {
      flightsGroup.flights.forEach(flight => {
        if(flight.impactList.length >3){
          largo = 3;
        }
        else{
          largo = flight.impactList.length;
        }
        
        if ((largo+count) <= 3) {
          count = count+largo;
          flightGroupA.push(flight);
        } else {
          count=0;
          count = largo + count;
          obj = { filaA: flightGroupA };
          result.push(obj);
          flightGroupA = [];
          flightGroupA.push(flight)
        }

      });
    });
    obj = { filaA: flightGroupA };
    result.push(obj);
    flightGroupA = [];



    return result;
  }


  /**
   * Apply user filters to critical flights
   * @param {array} flightsList 
   * @param {object} config 
   */
  filterCriticalFlights = (flightsList, config) => {
    /** @type {array} */
    const filialOpFilter = config.dataFiltersConfig?.filialOpFilter ? config.dataFiltersConfig.filialOpFilter : FILIAL_GROUPS;
    /** @type {array} */
    const excludedFleets = config.criticalFlightsConfig?.excludedFleets ? config.criticalFlightsConfig.excludedFleets : [];
    /** @type {array} */
    const excludedTails = config.criticalFlightsConfig?.excludedTails ? config.criticalFlightsConfig.excludedTails : [];
    /** @type {array} */
    const alertsFilter = config.criticalFlightsConfig?.fltAlertsFilter ? config.criticalFlightsConfig.fltAlertsFilter : FLIGHT_ALERT_LIST;
    /** @type {number} */
    const rangeFilter = config.criticalFlightsConfig?.fltRangeFilter ? config.criticalFlightsConfig.fltRangeFilter : 4;

    return flightsList.filter(f => {
      return filialOpFilter.includes(f.operatorType) && this.hasAnyFilteredImpact(alertsFilter, f.impactList) && !excludedTails.includes(f.cdgMatricula)
        && !excludedFleets.includes(f.sbFlota) && moment.utc(f.arrvlDateTime, 'DD/MM/YYYY HH:mm:ss').isBetween(moment.utc(), moment.utc().add(rangeFilter, 'h'))
    });
  }

  /**
   * Check if any flight impact is presented in the filter
   * @param {array} impactFilter 
   * @param {array} impactList 
   */
  hasAnyFilteredImpact = (impactFilter, impactList) => {
    let result = false;
    impactList.forEach(imp => {
      if (impactFilter.includes(imp.toUpperCase())) {
        result = true;
        return;
      }
    });
    return result;
  }

}

export default CriticalFlightsService;