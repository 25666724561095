import { useHistory } from "react-router-dom";
import './BackButton.css';

export default function BackButton(){

    let history = useHistory();
    return (
        <>
          <button className ="back-button"  onClick={() => history.push('/options')}> {"<" }</button>
        </>
    );

}