import React, { useState, useEffect } from 'react';
import './ContingencyCard.css';
import FlightGroup from './FlightGroup/FlightGroup';
import ContingencyService from '../../service/ContingencyService';
import GenericCounter from '../GenericCounter/GenericCounter';
import GenericTitle from '../GenericTitle/GenericTitle';
import Loader from '../Loader/Loader';
import { currentSlide } from '../../hooks/carrousel';
import {streamContingencyFlights} from '../../firebase';

let isLoading = true;
let actualSlide = 1;
let intervalId;
let lastUpdate;
let groupedFlightsList = [];
const contingencyService = new ContingencyService();

function ContingencyCard(props) {
  const [state, setState] = useState({currentDom: '', contingencyList: []});

  // It's CRITICAL to keep [] as the second argument to useEffect. Otherwise, we'll rack up UUUGE firestore bills due to opening a gazillion connections
  useEffect(() => {
    const unsubscribe = streamContingencyFlights({
      next: (querySnapshot) => {
        isLoading = false;
        if (querySnapshot.data()) {
          lastUpdate = querySnapshot.data().lastUpdate;
          setState({contingencyList: querySnapshot.data().data});
          moveSlide(actualSlide);
        } else {
          lastUpdate = new Date();
          setState({contingencyList: []});
        }      
      },
      error: (e) => {
        console.error(e);
        isLoading = false;
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    currentSlide(actualSlide);
    if (!intervalId) {
      intervalId = setInterval( () => moveSlide(actualSlide + 1), 25000);
    }
    return () => cleanUpInterval();
  }, [props]);

  const cleanUpInterval = () => {
    clearInterval(intervalId);
    intervalId = null;
  }

  groupedFlightsList = contingencyService.applyConfigAndGroupByOperator(state.contingencyList, props);

  const moveSlide = (slideNum) => {
    if(slideNum > groupedFlightsList.length && groupedFlightsList.length !== undefined) {
      slideNum = 1;
    }
    actualSlide = slideNum;
    setState(prevState => ({...prevState, currentDom: groupedFlightsList.length > 0? groupedFlightsList[slideNum-1].type : ''}))
    currentSlide(slideNum);
  }

  let altCount = 0;
  let gtbCount = 0;
  let atbCount = 0;
  let cldCount = 0;
  let originsList = {};

  groupedFlightsList.forEach( (ops) => {
      ops.flights.forEach( (flight)=> {
        if(originsList[flight.dprtrAirport] === undefined)
          originsList[flight.dprtrAirport] = 1;
        else
          originsList[flight.dprtrAirport] += 1;

        if(flight.alertAlt === "ALT")
          altCount += 1;
        else if(flight.alertAlt === "GTB")
          gtbCount += 1;
        else if(flight.alertAlt === "ATB")
          atbCount += 1;
        else if(flight.alertAlt === "CLD")
          cldCount += 1;
      })
    }
  );
    
  const orderOrigins = Object.entries(originsList).sort((a,b) => b[1]-a[1])

  return ( 
    <div className="contingency-card">
      {!isLoading && (
      <>
        <div className="contingency-header">
          <GenericTitle title={"Contingency Flights"} color={"#EB1451"} subTitle={state.currentDom} lastUpdate={ lastUpdate }/>

          <div className="countergrp-a">

            <GenericCounter cName={"counter-a"} title={"ALT"} count={altCount}/>
            <GenericCounter cName={"counter-a"} title={"GTB"} count={gtbCount}/>
            <GenericCounter cName={"counter-a"} title={"ATB"} count={atbCount}/>
            <GenericCounter cName={"counter-a"} title={"CLD"} count={cldCount}/>
          
          </div>

          <div className="countergrp-b">

            {
              orderOrigins.map((value,index) => {
                if(index < 4)
                  return <GenericCounter key={index} cName={"counter-b"} title={value[0]} count={value[1]}/>
                else
                  return null;
              })
              
            }
            
          </div>

        </div>

        <div className="contingency-flight-cards">

          {
            groupedFlightsList.map((flightGrp, indx) => (
              <FlightGroup key={indx} {...{ flightGrp }} />
            ))
          }

          
          <div className="carousell-dots">
            {
              groupedFlightsList.map((flightGrp, indx) => (
                <span className="dot" key={indx} onClick={ () => moveSlide(indx+1) }></span>
              ))
            }
          </div>
          
        </div> 
      </>
      )}
      
      {/* Loader */ isLoading && (<> <Loader id='ctg-loader'/> </> )}
    </div>

  );  
}

export default ContingencyCard;