import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import './CriticalFlightsConfig.css';
import {Prompt} from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import GenericSnackbar from '../../GenericSnackbar/GenericSnackbar';
import usePreventReload from '../../../hooks/usePreventReload';
import {FLEETS_LIST, TAILS_LIST, FLIGHT_ALERT_LIST, RANGE_LIST} from '../../../utils/Constants';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      width: '2.3vh',
      height: 'auto'
    },
    color: '#818181',     
  } 
})

function CriticalFlightsConfig(props) {
    const classes = useStyles();
    const [isDirty, setDirty, setClean] = usePreventReload();
    const [state, setState] = React.useState(props.configurationData? props.configurationData : {
        excludedFleets: [],
        excludedTails: [],
        lastModDate: ''
    })

    const handleChangeFleets = (event, value, action) => {
        setDirty();
        setState({ ...state, excludedFleets: value });
    };

    const handleChangeTails = (event, value, action) => {
        setDirty();
        setState({ ...state, excludedTails: value });
    };

    const handleSelectChange = (event) => {
        setDirty();
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const [snackbar, setSnackbar] = React.useState('');
    const saveChanges = () => {        
        const modDate = new Date();
        state.lastModDate = modDate.toUTCString();
        props.updateConfigurationData('criticalFlightsConfig', state).then(() =>{
            setSnackbar({ msg: "Succesfully saved changes!", date: new Date(), severity: "success" });
            setState({ ...state, lastModDate: modDate });
            setClean();
        }, error => {
            console.error(error);
            setSnackbar({ msg: "Error saving changes. Try again later", date: new Date(), severity: "error" });
        });
    }

    return (
      <div className="critical-flights-conf">
        <div className="crit-flights-title">
          <span>Critical Flights</span>
        </div>

        <Tabs className="crit-flights-tab">
          <TabList>
            <Tab>Filters</Tab>
          </TabList>

        <TabPanel>
          <div className="desc">Select what you <b>do not want</b> to see in this section of Video Wall.</div>
          <div className="crit-flights-filters">
              {/* Excluded Fleet */}
            <div className="crit-flights-autocomplete">
              <div className="ac-label">Excluded Fleets</div>
              <Autocomplete
                  multiple
                  freeSolo
                  limitTags={3}
                  id="multiple-limit-tags-fleets"
                  options={FLEETS_LIST}
                  classes={{tag: 'ac-tag'}}
                  disableCloseOnSelect={true}
                  renderOption={(option, { selected }) => (
                      <div className='option'>
                        <Checkbox
                            color="primary" classes={{root: 'ac-checkbox'}}
                            style={{ marginRight: '1vh' }}
                            checked={selected}
                            value={option}
                        />
                          {option}
                      </div>
                  )}
                  value={state.excludedFleets}
                  onChange={handleChangeFleets}
                  renderInput={(params) => (
                  <TextField {...params}  variant="outlined" label="Fleet" />)}
              />
            </div>
              {/* Excluded Tail */}
            <div className="crit-flights-autocomplete">
              <div className="ac-label">Excluded Tails</div>
              <Autocomplete
                  multiple
                  freeSolo
                  limitTags={3}
                  id="multiple-limit-tags-tails"
                  options={TAILS_LIST}
                  classes={{tag: 'ac-tag'}}
                  disableCloseOnSelect={true}
                  renderOption={(option, { selected }) => (
                      <div className='option'>
                          <Checkbox
                              color="primary" classes={{root: 'ac-checkbox'}}
                              style={{ marginRight: '1vh' }}
                              checked={selected}
                              value={option}
                          />
                          {option}
                      </div>
                  )}
                  value={state.excludedTails}
                  onChange={handleChangeTails}
                  renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Tail" />)}
              />
            </div>
          </div>             
          
          <div className="desc">Select what you <b>want to see</b> in this section of  Video Wall.</div>
          <div className="crit-flights-filters">
            {/* Select Flight alerts */}
            <div className="crit-flights-select">
              <FormControl variant="outlined" classes={{root: "form-select" }}>
                <TextField
                select
                label="Flight alerts"
                onChange={handleSelectChange}
                variant="outlined"
                name='fltAlertsFilter'
                SelectProps={{
                    classes: {select: 'select'},
                    multiple: true,
                    value: state.fltAlertsFilter ? state.fltAlertsFilter : [],
                    renderValue: (value) => value.join(', ')
                }}
                >
                  {FLIGHT_ALERT_LIST.map( (value) => {return <MenuItem classes={{root: 'option'}} key={value} value={value}>
                      <Checkbox className={classes.root} color="primary" checked={state.fltAlertsFilter? state.fltAlertsFilter.indexOf(value) > -1 : false} />
                      <ListItemText disableTypography={true} primary={value} />
                  </MenuItem>})}
                </TextField>
              </FormControl>
            </div>
            {/* Select Program flight range you want to see */}
            <div className="crit-flights-select">
              <FormControl variant="outlined" classes={{root: "form-select" }}>
                <TextField
                select
                label="Program flight range"
                onChange={handleSelectChange}
                variant="outlined"
                name='fltRangeFilter'
                SelectProps={{
                    classes: {select: 'select'},
                    value: state.fltRangeFilter ? state.fltRangeFilter : 4,
                }}
                >
                  {RANGE_LIST.map( (value, index) => {return <MenuItem classes={{root: 'option'}} key={value} value={index+1}>{value}</MenuItem>})}
                </TextField>
              </FormControl>
            </div>
          </div>          
        </TabPanel>
        </Tabs>


        {/* Sorts section */}



        {/* Buttons section */}
        <div className="crit-flights-buttons">
          <div className="last-mod">                   
            Last modification made: {state.lastModDate ? <Moment format="DD/MM/yyyy HH:mm" tz="UTC">{state.lastModDate}</Moment> : null}
          </div>
          <Button variant="contained" classes={{root: 'vw-button'}} onClick={saveChanges} disableElevation>
            Apply changes to video wall
          </Button>
        </div>

        {/* Generic Snackbar */}
        {snackbar ? <GenericSnackbar key={snackbar.date} severity={snackbar.severity} message={snackbar.msg}/> : null}
        {/* Custom Warning Prompt */}
        <Prompt when={isDirty} message='Custom prompt' />
      </div>
    );
    
}

export default CriticalFlightsConfig;