import {firstBy} from "thenby";
import {FILIAL_GROUPS} from '../utils/Constants';

class ContingencyService {

  /**
   * Apply user filters to contingency flights
   * @param {array} flightsList 
   * @param {object} config 
   */
  filterContingencyFlights = (flightsList, config) => {
    /** @type {array} */
    const filialOpFilter = config.dataFiltersConfig?.filialOpFilter? config.dataFiltersConfig.filialOpFilter : FILIAL_GROUPS;
    /** @type {array} */
    const excludedFleets = config.contingencyFlightsConfig?.excludedFleets? config.contingencyFlightsConfig.excludedFleets : [];
    /** @type {array} */
    const excludedTails = config.contingencyFlightsConfig?.excludedTails? config.contingencyFlightsConfig.excludedTails : [];
    return flightsList.filter(f => {
      return filialOpFilter.includes(f.operatorType) && !excludedFleets.includes(f.sbFlota) && !excludedTails.includes(f.cdgMatricula)
    });
  }

  /**
   * Apply user configuration and group contingency flights by operator
   * @param {array} flightsList 
   * @param {object} config 
   */
  applyConfigAndGroupByOperator(flightsList, config) {
    const filteredFlights = this.filterContingencyFlights(flightsList, config);
    this.applySortConfig(filteredFlights, config);
    let result = [];
    filteredFlights.forEach(element => {
      const flightGroup = result.find(fg => fg.type === element.operatorType && fg.flights.length < 5);
      if (flightGroup) {
        flightGroup.flights.push(element);
      } else {
        const obj = {type: element.operatorType, flights: []};
        obj.flights.push(element);
        result.push(obj);
      }
    });
    return result;
  }

  /**
   * Apply user sort configuration to contingency flights
   * @param {array} flightsList 
   * @param {object} config 
   */
  applySortConfig(flightsList, config) {
    /** @type {array} */
    const alertSort = config.contingencyFlightsConfig?.alertSort? config.contingencyFlightsConfig.alertSort : [];
    /** @type {array} */
    const statusSort = config.contingencyFlightsConfig?.statusSort? config.contingencyFlightsConfig.statusSort : [];
    const crewConnectionSort = config.contingencyFlightsConfig?.crewConnectionSort? config.contingencyFlightsConfig.crewConnectionSort : 'asc';
    const paxConexSort = config.contingencyFlightsConfig?.paxConexSort? config.contingencyFlightsConfig.paxConexSort : 'desc';

    flightsList.sort(
      firstBy(function (v1, v2) { return alertSort.findIndex(i => i.name === v1.alertAlt) - alertSort.findIndex(i => i.name === v2.alertAlt); } )
      .thenBy(function (v1, v2) { return statusSort.findIndex(i => i.name === v1.operationStatus) - statusSort.findIndex(i => i.name === v2.operationStatus); } )
      .thenBy("crewConnTime", crewConnectionSort)
      .thenBy("paxConexNumber", paxConexSort)
    );
  }

}

export default ContingencyService;