import './FloatingBtn.css';
import { useEffect } from 'react';
import './materialize.css';
import M from 'materialize-css';
import { auth } from '../../firebase';
import { useHistory } from "react-router-dom";

function FloatingBtn(props) {
  const history = useHistory();

  const logout = () => {
    auth.signOut().then( () => {history.push('/login')});
  }

  const routeToConfig = () => {
    history.push('/configuration');
  }

  const routeToHome = () => {
    history.push('/options');
  }

  useEffect(() => {

    const elems = document.querySelectorAll('.fixed-action-btn');
    M.FloatingActionButton.init(elems, {
      direction: 'top',
      hoverEnabled: true
    });

  }, []);

  return (
  <div className="fixed-action-btn">
    <button className="btn-floating btn-large blue">
      <i className="large material-icons">dehaze</i>
    </button>
    <ul>
      <li>
        <button className="btn-floating red" onClick={logout}>
          <i className="material-icons">exit_to_app</i>
        </button>
        <span className="tooltiptext">Log out</span>
      </li>
      {(props.userRole === 'OTP' || props.userRole === 'SUPER USER') &&
        <li>
          <button className="btn-floating blue" onClick={routeToHome}>
            <i className="material-icons">home</i>
          </button>
          <span className="tooltiptext">Back to home</span>
        </li>
      }
      <li>
        <button className="btn-floating yellow" onClick={routeToConfig}>
          <i className="material-icons">settings</i>
        </button>
        <span className="tooltiptext">Configuration</span>
      </li>
      {props.userRole !== 'OTP' && 
        <li>
          <button className="btn-floating green" onClick={props.handleOpen}>
            <i className="material-icons">local_phone</i>
          </button>
          <span className="tooltiptext">Phone Dialer</span>
        </li>
      }
    </ul>
  </div>
  );
}
  
export default FloatingBtn;