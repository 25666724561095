import React, { Component } from 'react';
import './SummaryLimopsCounter.css';

class SummaryLimopsCounter extends Component {
  
  render(){
    return(
      <div className="sl-counter">
        <p className="sl-counter-title">{this.props.title}</p>
        <p className="sl-num">{this.props.count}</p>
      </div>
    );
  }

}

export default SummaryLimopsCounter;