import React from 'react';
import './CriticalAircraftGroup.css';
import CriticalAircraftTable from '../CriticalAircraftTable/CriticalAircraftTable';

function CriticalAircraftGroup(props) {

  let criticalGrp = [];

  props.flightGrp.forEach((element, index) => {
    let flight = <CriticalAircraftTable key={index} flightData={element} />

    criticalGrp.push(flight);
  });

  return (
    <div className="ca-mySlides ca-fade">

      {criticalGrp}

    </div>
  );
}

export default CriticalAircraftGroup;