import React, { Component } from 'react';
import './Loader.css';
import ZlataLoader from './ZlataLoader';
import Plane from '../../vector/loader-plane.png';
import BluePlane from '../../vector/blue-loader-plane.png';


class Loader extends Component {
    loader;

    componentDidMount() {
        this.loader = new ZlataLoader({
            id: this.props.id,
            imgSrc: this.props.blue? BluePlane: Plane,
            imageWidth: this.props.blue? 25 : 50,
            mode: 'single',
            radius: this.props.blue? 15 : 25,
            lineWidth: 2,
            lineColor: this.props.blue? "#140964" : "#fff",
            speed: -1
        })
    }

    render() {
        return (
            <div className='loader' id={this.props.id}> </div>
        );
    }
}

export default Loader;