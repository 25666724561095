import React, {useState, useEffect} from 'react';
import './PhoneDialer.css';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PhoneLogo from './phone-dial.svg';
import CrossVector from './cross-vector.svg';
import FloatingBtn from '../FloatingBtn/FloatingBtn';
import GenericSnackbar from '../GenericSnackbar/GenericSnackbar';
import {auth,getPhoneData} from '../../firebase';
import axios from 'axios';
import Tooltip from '@material-ui/core/Tooltip';

let filteredExtensions = {extensionData: []};
let filteredContacts = [];
let airportList = [];
const HUB_CORP_DOMAIN = process.env.REACT_APP_HUB_CORP_DOMAIN;

function PhoneDialer(props) {
    const [state, setState] = useState({contacts: [], extensions: [], open: false, country: '', extension: '', airport: '', disableCall: false});
    
    useEffect(() => {
        getPhoneData().then((data) => {
            data.contacts.forEach(value => airportList.push(value.airportCode) );
            filteredContacts = data.contacts;
            setState({contacts: data.contacts, extensions: data.extensions, open: false, country: '', extension: '', airport: '', disableCall: false });
        });
    }, []);
  
    const handleOpen = () => {
      setState({ ...state, open: true });
    };
  
    const handleClose = () => {
      setState({ ...state, open: false });
    };

    const handleChangeExtension = (event) => {
      setState({ ...state, extension: event.target.value });
    };

    const handleChangeCountry = (event) => {
      filteredExtensions = state.extensions.find(ext => ext.country === event.target.value);
      setState({ ...state, country: event.target.value, extension: '' });
    };

    const handleChangeAirport = (event, value, action) => {
      if (value) {
        filteredContacts = state.contacts.filter((contact) => contact.airportCode === value);
      } else {
        filteredContacts = state.contacts;
      }
      setState({ ...state, airport: value });
    };

    const makeCall = (number) => {
      setState({...state, disableCall: true,  snackbar: { msg: "Starting Calling...", date: new Date(), severity: "info" }});
      if (state.extension !== "" && state.country !== "") {
        auth.currentUser.getIdToken().then( async (idToken) => {
            try {
              const url = `https://${HUB_CORP_DOMAIN}/videowall-web-dialer/makecall`;
              const headers = { Authorization: `Bearer ${idToken}` };
              const body = {deviceName: 'SEP'+state.extension, lineNumber: "1", number: number, country: state.country};
              const response = await axios.post(url, body, { headers: headers });
              if (response.data?.codeResponse !== 0) {
                setState({...state, snackbar: { msg: "Call Failed", date: new Date(), severity: "error" }});
              } else {
                setState({...state, snackbar: { msg: "Call Success", date: new Date(), severity: "success" }});  
              }
            } catch (e) {
              console.error(e);
              setState({...state, snackbar: { msg: "Call Failed", date: new Date(), severity: "error" }});
            }
        }).catch((error) => {
            console.error(error);
            setState({...state, snackbar: { msg: "Call Failed", date: new Date(), severity: "error" }});
        });
      } else {
        setState({...state, snackbar: { msg: "Country or Extension are missing", date: new Date(), severity: "error" }});
      }      
    }

    const phoneIcon = (phoneNumber) => (    
      <Tooltip title="To Call">
        <img src={PhoneLogo} alt="phone" onClick={() => makeCall(phoneNumber)}></img>
      </Tooltip>
    );

    const body = (
        <div className="dialup-modal">
            <img src={CrossVector} alt="err" className="modal-cross" onClick={handleClose}></img>
            <div className="modal-title">Telephone Dialing</div>
            <p className="modal-subtitle">Select your extension number.</p>

            <div className="dialup-modal-extensions">
                <FormControl variant="outlined" className="dialup-extension-one">
                    <TextField 
                        select
                        label="Country"
                        variant="outlined"
                        className="select-extension-one"
                        onChange={handleChangeCountry}
                        SelectProps={{
                          value: state.country
                        }}
                    >
                        <MenuItem value="BR">BR</MenuItem>
                        <MenuItem value="CL">CL</MenuItem>
                    </TextField>
                </FormControl>
                <FormControl variant="outlined" className="dialup-extension-two">
                    <TextField
                        select
                        label="Extension"
                        variant="outlined"                
                        className="select-extension-two"
                        disabled={state.country === ""}
                        onChange={handleChangeExtension}
                        SelectProps={{
                            classes: {select: 'select'},
                            value: state.extension
                        }}
                    >
                        {filteredExtensions.extensionData.map((value, index) => (
                        <MenuItem key={index} value={value.mac}>{value.telephoneExtension}</MenuItem>      
                        ))}
                    </TextField>
                </FormControl> 
            </div>
            <hr />
            <p className="contacts-label">Contacts</p>
            <Autocomplete
                className="dialup-modal-contacts"
                options={airportList}
                getOptionLabel={(option) => option}
                onChange={handleChangeAirport}
                value={state.airport}
                renderInput={(params) => 
                    <TextField {...params} label="Filter by airport" variant="outlined" />}
            />
            
            <div className="numbers-body">
            {filteredContacts.map((base, index) => (
                <div className="dialup-modal-base" key={index}>
                    <p className="airport">{base.airportCode}</p>
                    {base.contactList.map((contact, index) => 
                        <div key={index} className="dialup-row">
                            <div className="dialup-left">
                                <div className="dialup-bold">{contact.fullName && contact.fullName.trim() !== '' ? contact.fullName : contact.role}</div>
                                <div>{contact.fullName && contact.fullName.trim() !== '' ? contact.role : ''}</div>
                            </div>  
                            <div className="dialup-middle">
                              {contact.landPhone && <div>{contact.landPhone}</div>}
                              {contact.mobilePhone && <div>{contact.mobilePhone}</div>}
                              {contact.otherPhone && <div>{contact.otherPhone}</div>}
                            </div>
                            <div className={state.disableCall ? "dialup-right disable" : "dialup-right" } >
                              {contact.landPhone && phoneIcon(contact.landPhone)}
                              {contact.mobilePhone && phoneIcon(contact.mobilePhone)}
                              {contact.otherPhone && phoneIcon(contact.otherPhone)}     
                            </div>
                        </div>                        
                    )}
               </div>   
            ))}            
            </div>
        </div>
        
    );
    return (
        <div>
          {props.userRole !== 'VIDEOWALL' ? <FloatingBtn handleOpen={handleOpen} userRole={props.userRole}/>: null}
          
          {<Dialog
            maxWidth='sm'
            open={state.open}
            onClose={handleClose}
            classes={{paper: "phone-dialog"}}
            aria-labelledby="phone-modal"
          >
            {body}
          </Dialog>}
    
          {/* Generic Snackbar */}
          {state.snackbar ? <GenericSnackbar key={state.snackbar.date} severity={state.snackbar.severity} message={state.snackbar.msg}/> : null}
        </div>
      );
}

export default PhoneDialer;