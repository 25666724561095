import React, { useEffect, useState } from "react";
import { auth, firestore } from "../firebase";

export const AuthContext = React.createContext();

function AuthProvider(props) {
    const [authState, setAuthState] = useState({user: null, initLoad: true});

    useEffect(() => {
        async function validateUser(email) {
            let user = {};
            try {
                user = await firestore.collection('videowall-users').doc(email.toLowerCase()).get(); 
            } catch (err) {
                setAuthState({unauthorized: true, redirect: true});
            }           
            return user;
        }
        auth.onAuthStateChanged( (userAuth) => {
            setAuthState({loading: true, initLoad: true});
            if (userAuth && !userAuth.isAnonymous) {                
                validateUser(userAuth.email).then(user => {
                    if (user.exists && user.data().role &&  user.data().cco) {
                        setAuthState({user: userAuth, role: user.data().role, cco: user.data().cco});
                    } else {
                        setAuthState({unauthorized: true});
                    }
                });
            } else {
                setAuthState({redirect: true});
            }
        });
    }, []);

    return (
        <AuthContext.Provider value={authState}>
          {props.children}
        </AuthContext.Provider>
    );

}

export default AuthProvider;
