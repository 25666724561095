import React from 'react';
import './PercentBoxDep0.css';

function PercentBox(props) {
  const colorGreen= '#00AF25';
  const colorRed= '#EB5757';
  const colorYellow= '#FFC700';

  const getColor = (value, goal, type) => {
    if(value && goal){
      const pivot = Number((parseFloat(goal) * 0.04).toFixed(1));
      const lowLimit = parseFloat(goal) - pivot;
      const highLimit = parseFloat(goal) + pivot;
      let color = "#176CDB";
      if (type === 'dep') color = parseFloat(value) < lowLimit ? colorRed : parseFloat(value) >= parseFloat(goal) ? colorGreen : colorYellow;
      if (type === 'affected') color = parseFloat(value) > highLimit ? colorRed : parseFloat(value) <= parseFloat(goal) ? colorGreen : colorYellow;
      return {
        borderLeftColor: color 
      };
    }else{
      return {
        borderLeftColor: "#176CDB" 
      };
    }
  }

  return (
    <div style={getColor(props.value, props.goal, props.type)} className={props.isVeo? "row-percent-box-veo" : "row-percent-box"}>
      <span className={props.isVeo? "percent-data-veo" : "percent-data"}>{props.value}</span>
    </div>
  );

}

export default PercentBox;