import React from 'react';
import './SummaryLimopsOper.css';

function SummaryLimopsOper(props) {

  const size = (value) => {
    if (String(value).includes('/')) {
      return '11%';
    } else {
      return '6%';
    }

  }
  const getColor = (value, limops) => {

    if (value >= limops.min && value < limops.max) {
      return {
        borderLeftColor: "#FFC700",
        width: size(value)
      };
    } else if (value >= limops.max) {
      return {
        borderLeftColor: "#EB5757",
        width: size(value)
      };
    } else if (value < limops.min) {
      return {
        borderLeftColor: "#ffffff",
        width: size(value)
      };
    } else {
      return {
        width: size(value)
      };
    }
  }

  return (
      <span style={getColor(props.value, props.getValue)} className='summary-limops-oper-span'>{props.value}</span>
  );

}

export default SummaryLimopsOper;
