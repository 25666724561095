import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import './OtpConfig.css';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import GenericSnackbar from '../../GenericSnackbar/GenericSnackbar';
import usePreventReload from '../../../hooks/usePreventReload';
import {Prompt} from 'react-router-dom';
import {OTP_GROUPS} from '../../../utils/Constants';

const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& svg': {
        width: '2.3vh',
        height: 'auto'
      },
    } 
})

function OtpConfig(props) {
    const classes = useStyles();
    const [isDirty, setDirty, setClean] = usePreventReload();
    const otpFiltersConfig = props.configurationData;
    const [state, setState] = React.useState(otpFiltersConfig? otpFiltersConfig : {
        businessGroupFilter: [],
        lastModDate: ''
    })

    const isAllSelected = () => { 
        let allSelected = true;
        OTP_GROUPS.forEach(g => {
            if (state.businessGroupFilter.indexOf(g) === -1) {
                allSelected = false;
            }
        })
        return allSelected;
    }

    const handleChange = (event) => {
        setDirty();
        const index = state.businessGroupFilter.indexOf(event.target.value);
        if (index > -1) {
            state.businessGroupFilter.splice(index, 1)
        } else {
            state.businessGroupFilter.push(event.target.value)
        }
        setState({ ...state, businessGroupFilter: state.businessGroupFilter });
    };

    const handleChangeAll = (event) => {
        setDirty();
        OTP_GROUPS.forEach(g => {
            const index = state.businessGroupFilter.indexOf(g);
            if (index === -1 && event.target.checked) {
                state.businessGroupFilter.push(g)
            } else if (!event.target.checked) {
                state.businessGroupFilter.splice(index, 1)
            }
        })
        setState({ ...state, businessGroupFilter: state.businessGroupFilter });
    };

    const [snackbar, setSnackbar] = React.useState('');

    const saveChanges = () => {        
        const modDate = new Date();
        state.lastModDate = modDate.toUTCString();
        props.updateConfigurationData('otpConfig', state).then(() =>{
            setSnackbar({ msg: "Succesfully saved changes!", date: new Date(), severity: "success" });
            setState({ ...state, lastModDate: modDate });
            setClean();            
        }, error => {
            console.error(error);
            setSnackbar({ msg: "Error saving changes. Try again later", date: new Date(), severity: "error" });
        });
    }

    return (
        <div className="otp-filters">
            {/* Select filiales Operators section */}       
            <div className="otp-title">
                <span>OTP</span>
            </div>
            <div className="otp-config">
                <div className="otp-select">
                    <p className="desc">Select what you want to see on <b>OTP</b></p>  
                    <div className="otp-form"> 
                        <FormGroup row classes={{root: 'fg-row'}}>                            
                            <div className="business-group">
                                <p>ALL</p>
                            </div>
                            <FormControlLabel
                                control={<Checkbox color="primary" className={classes.root} checked={isAllSelected()} onChange={handleChangeAll}/>}
                                label={<Typography classes={{root: 'cb-typo'}}></Typography>}
                                classes={{root: 'cb-label'}}
                            />
                        </FormGroup>   
                        <Divider />        
                        {OTP_GROUPS.map((group) => {
                            return(
                            <FormGroup row key={group} classes={{root: 'fg-row'}}>                            
                                <div className="business-group">
                                    <p>{group.split(' ')[1]}</p>
                                </div>
                                <FormControlLabel
                                    control={<Checkbox color="primary" className={classes.root} name={group} checked={state.businessGroupFilter.includes(group)} onChange={handleChange} value={group} />}
                                    label={<Typography classes={{root: 'cb-typo'}}>{group.split(' ')[0]}</Typography>}
                                    classes={{root: 'cb-label'}}
                                />
                            </FormGroup>)
                        })}  
                    </div>                      
                </div>
            </div>

            {/* Buttons section */}
            <div className="otp-buttons">
                <div className="last-mod">                   
                    Last modification made: {state.lastModDate ? <Moment format="DD/MM/yyyy HH:mm" tz="UTC">{state.lastModDate}</Moment> : null}
                </div>
                <Button variant="contained" classes={{root: 'vw-button'}} onClick={saveChanges} disableElevation>
                    Apply changes to video wall
                </Button>
            </div>

            {/* Generic Snackbar */}
            {snackbar ? <GenericSnackbar key={snackbar.date} severity={snackbar.severity} message={snackbar.msg}/> : null}
            {/* Custom Warning Prompt */}
            <Prompt when={isDirty} message='Custom prompt' />
        </div>      
    );
    
}

export default OtpConfig;