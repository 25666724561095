import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import usePreventReload from '../../../hooks/usePreventReload';
import {Prompt} from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import GenericSnackbar from '../../GenericSnackbar/GenericSnackbar';
import {BR_AIRPORTS, SSC_AIRPORTS} from '../../../utils/Constants';

function GroundSupportConfig(props) {
    const [isDirty, setDirty, setClean] = usePreventReload();
    const [state, setState] = React.useState(props.configurationData? props.configurationData : {
        airportsFilter: []
    });

    const [snackbar, setSnackbar] = React.useState();
    const saveChanges = () => {
        const modDate = new Date();
        state.lastModDate = modDate.toUTCString();
        props.updateConfigurationData('groundSupportConfig', state).then(() =>{
            setSnackbar({ msg: "Succesfully saved changes!", date: new Date(), severity: "success" });
            setState({ ...state, lastModDate: modDate });
            setClean();
        }, error => {
            console.error(error);
            setSnackbar({ msg: "Error saving changes. Try again later", date: new Date(), severity: "error" });
        });
    }

    const handleSelectChange = (event) => {
        setDirty();
        let index;
        let filters = event.target.value;

        if (filters.includes('BR')) {
            if (isAllSelected('BR')) {
                BR_AIRPORTS.forEach(a => {
                    index = filters.indexOf(a);
                    filters.splice(index, 1);
                })
            } else {
                BR_AIRPORTS.forEach(a => {
                    index = filters.indexOf(a);
                    if (index === -1) {
                        filters.push(a);
                    }
                })
            }
            index = filters.indexOf('BR');
            filters.splice(index, 1);
        } 
        
        if (filters.includes('SSC')) {
            if (isAllSelected('SSC')) {
                SSC_AIRPORTS.forEach(a => {
                    index = filters.indexOf(a);
                    filters.splice(index, 1);
                })
            } else {
                SSC_AIRPORTS.forEach(a => {
                    index = filters.indexOf(a);
                    if (index === -1) {
                        filters.push(a);
                    }
                })
            }
            index = filters.indexOf('SSC');
            filters.splice(index, 1);
        }
        
        setState({ ...state, airportsFilter: filters });
    }

    const isAllSelected = (country) => {
        let isAllSelected = true;
        if (country === 'BR') {
            BR_AIRPORTS.forEach(a => {
                if (state.airportsFilter.indexOf(a) === -1) {
                    isAllSelected = false;
                }
            })
        } else {
            SSC_AIRPORTS.forEach(a => {
                if (state.airportsFilter.indexOf(a) === -1) {
                    isAllSelected = false;
                }
            })
        }
        return isAllSelected;
    }

    return (
        <div className="ground-support-config">
            <div className="w-title">
                <span>Ground Support</span>
            </div>
            <Tabs className="w-tab">
                <TabList>
                    <Tab>Filters</Tab>
                </TabList>

                {/* Filters section */}
                <TabPanel>
                    <div className="desc">Select what you want to see on Ground Support.</div>

                    <div className="w-filters">
                        <FormControl variant="outlined" classes={{root: "w-select" }}>
                            <TextField
                            select
                            label="Select Airport"
                            onChange={handleSelectChange}
                            variant="outlined"
                            SelectProps={{
                                multiple: true,
                                value: state.airportsFilter,
                                renderValue: (value) => value.join(', ')
                            }}
                            >
                                <MenuItem classes={{root: 'option-all'}} value="BR">
                                    <Checkbox color="primary" checked={isAllSelected("BR")} />
                                    BR
                                </MenuItem>
                                {BR_AIRPORTS.map((arprt) => (
                                    <MenuItem classes={{root: 'w-option'}} key={arprt} value={arprt}>
                                        <Checkbox color="primary" checked={state.airportsFilter.indexOf(arprt) > -1} />
                                        {arprt}
                                    </MenuItem>                                    
                                ))}
                                <MenuItem classes={{root: 'option-all'}} value="SSC">
                                    <Checkbox color="primary" checked={isAllSelected("SSC")} />
                                    SSC
                                </MenuItem>
                                {SSC_AIRPORTS.map((arprt) => (
                                    <MenuItem classes={{root: 'w-option'}} key={arprt} value={arprt}>
                                        <Checkbox color="primary" checked={state.airportsFilter.indexOf(arprt) > -1} />
                                        {arprt}
                                    </MenuItem>                                    
                                ))}
                            </TextField>
                        </FormControl>
                    </div>        
                </TabPanel>
            </Tabs>

            {/* Buttons section */}
            <div className="w-buttons">
                <div className="last-mod">                   
                    Last modification made: {state.lastModDate ? <Moment format="DD/MM/yyyy HH:mm" tz="UTC">{state.lastModDate}</Moment> : null}
                </div>
                <Button variant="contained" classes={{root: 'vw-button'}} onClick={saveChanges} disableElevation>
                    Apply changes to video wall
                </Button>
            </div>

            {/* Generic Snackbar */}
            {snackbar ? <GenericSnackbar key={snackbar.date} severity={snackbar.severity} message={snackbar.msg}/> : null}
            {/* Custom Warning Prompt */}
            <Prompt when={isDirty} message='Custom prompt' />
        </div>      
    );
}

export default GroundSupportConfig;