import React, {useState, useEffect}  from 'react';
import moment from 'moment';
import './LatamNewsBand.css';
import Plane from '../../vector/plane.svg'
import {streamHeader} from '../../firebase';

let isLoading = true;
let executivesList = [];
let newsList = [];
let intervalId;

function LatamNewsBand(props) {
  const [state, setState] = useState({executivesList: [], newsList: []});

  useEffect(() => {
    const unsubscribe = streamHeader({
      next: (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.id === "executives") {
            executivesList = doc.data().data;
          }
          if (doc.id === "news"+props.userCCO) {
            newsList = doc.data().data;
          }
        });
        isLoading = false;
        updateStatus(executivesList, newsList)
      },
      error: (e) => {
        console.error(e);
      }
    });
    return unsubscribe;
  }, [props]);

  useEffect(() => {
    if (!intervalId) {
      intervalId = setInterval( () => updateStatus(executivesList, newsList), 60000);
    }
    return cleanUpInterval;
  }, []);

  const cleanUpInterval = () => {
    clearInterval(intervalId);
    intervalId = null;
  }

  const updateStatus = (executivesList, newsList) => {
    newsList.forEach(n => {
      if (moment.utc().isBetween(moment.utc(n.startDate), moment.utc(n.endDate), 'minutes', '[]')) {
        n.status = 'ACTIVE';
      } else if (moment.utc(n.endDate).isBefore(moment.utc())){
        n.status = 'EXPIRED';
      } else {
        n.status = 'PENDING';
      }
    });
    setState({executivesList: executivesList, newsList: newsList.filter(n => n.status === 'ACTIVE')});
  }

  return (
    <div className="latam-scroll">
      {!isLoading && (
        <div className="news">
          {state.newsList.map((news, indx) => (
            <div key={indx} className="message"><img src={Plane} className="message-img" alt="error"></img>{news.message}</div>
          ))}
          {state.newsList.length > 0 ? <img src={Plane} className="last-message-img" alt="error"></img> : null}
          {props.userCCO === 'SSC' && (
            <>
            <div>*****JEFATURAS&nbsp;DEL&nbsp;TURNO*****</div>
            {state.executivesList.map((exe, indx) => (
              exe.rol?.toUpperCase().includes("DIRECTOR") || exe.rol?.toUpperCase() === "ECO DE MANTENIMIENTO" ? 
              <div key={indx} className="executive"><img src={Plane} alt="error"></img>{exe.ejecutivo}&nbsp;-&nbsp;{exe.rol}&nbsp;-&nbsp;{exe.telefono}</div> : null
            ))}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default LatamNewsBand;