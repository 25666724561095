import React, { useEffect, useState } from 'react';
import Loader from '../Loader/Loader';
import OTPService from '../../service/OTPService';
import { streamOTP, getUserConfiguration } from '../../firebase';
import PunctualityVeo from './PunctualityVeo/PunctualityVeo';
import MonthPunctualityVeo from './PunctualityVeo/MonthPunctualityVeo';
import GenericTitle from '../GenericTitle/GenericTitle';
import LatamImg from './latam-otp.png';
import AzulImg from './azul-otp.png';
import GolImg from './gol-otp.png';
import './OtpVeo.css';
import TabOtp from './TabOtp/TabOtp';
import BackButton from './BackButton/BackButton';


let isLoading = true;
let dailyPunctualityListSplit = [];
let delayReasonList = [];
let lastUpdate, latamGoal, dailyPunctualityList, configurationData;
const otpService = new OTPService();
let percents = { gol: "", azul: "", latam: ""};

function Otp(props) {
  const [state, setState] = useState({dailyPunctualityList: [], configurationData: {}});
  const userEmail = props.user?.email;
  const userCCO = props.userCCO;

  useEffect(() => {   
    async function userConfig(email, cco) {
      const config = await getUserConfiguration(email, cco);
      return config;
    }
    const unsubscribe = streamOTP({
      next: (querySnapshot) => {
        dailyPunctualityList = [];
        delayReasonList = [];
        querySnapshot.forEach((doc) => {

          if (doc.data().lastUpdate) {
            lastUpdate = doc.data().lastUpdate;
          }
          if (doc.id === 'otpPunctuality' || doc.id === 'otpPunctualityHub'|| doc.id === 'otpPunctualityLATAM') {
            dailyPunctualityList = dailyPunctualityList.concat(doc.data().data ? doc.data().data : []);
          }
          if (doc.id === 'otpDelayReason' || doc.id === 'otpDelayReasonHub'|| doc.id === 'otpDelayReasonsLATAM') {
            delayReasonList = delayReasonList.concat(doc.data().data ? doc.data().data : []);
          }
          if(doc.data()?.rankingBR) {
            percents = doc.data().rankingBR;
          }
          if (doc.id === 'otpGoals') {
            latamGoal = doc.data().data ? doc.data().data : null;
          }
        });
        if (!configurationData) {
          userConfig(userEmail, userCCO).then((data) => {
            configurationData = data ? data : {};
            isLoading = false;
            setState({ dailyPunctualityList: dailyPunctualityList, configurationData: configurationData, lastUpdate: lastUpdate });
          });
        } else {
          isLoading = false;
          setState({ dailyPunctualityList: dailyPunctualityList, configurationData: configurationData, lastUpdate: lastUpdate });
        }
      },
      error: (e) => {
        console.error(e);
        isLoading = false;
      }
    });
    return unsubscribe;
  }, [userEmail, userCCO]);

  state.dailyPunctualityList.forEach(otp => {
    otp.delayReason = delayReasonList.filter(e => {
      const dlGroup = e.businessGroup? e.businessGroup : e.businessGroupHub? e.businessGroupHub : "ATAM TOTAL";
      const otpGroup = otp.businessGroup? otp.businessGroup : otp.businessGroupHub? otp.businessGroupHub : "ATAM TOTAL";
      return otpGroup === dlGroup;
    });
  
    otp.delayReason.sort((a,b) => Number(b.SSC_countDelayReason) - Number(a.SSC_countDelayReason));
  });
  dailyPunctualityListSplit = otpService.applyConfigurationAndSplit(state.dailyPunctualityList, state.configurationData);

  return (
    <div className="otp-veo-root">
      <p style={{visibility: 'hidden', margin: 0}}>_</p>
      <div className="otp-veo">
        {!isLoading && configurationData && (
        <>
          <div className="otp-veo-header ">
            <BackButton/>
            <GenericTitle  title={"OTP"} color={"#775DD3"} lastUpdate={state.lastUpdate}/>
            {userCCO === 'BR' &&
            <> 
            <div className="otp-veo-head-label  otp-veo-hidden">
              <p className="label-p1 otp-veo-hidden">STD 30´</p>
              <p className="label-p2 otp-veo-hidden">TODAY</p>
            </div>
            <div className="otp-veo-airlines-percents otp-veo-hidden">
              <div className="otp-veo-latam otp-veo-hidden">
                <img className="otp-veo-hidden" src={LatamImg} alt="error"/>
                <p className="otp-veo-air-pcnts otp-veo-hidden">{percents.latam}%</p>
              </div>
              <div className="otp-veo-azul otp-veo-hidden">
                <img className="otp-veo-hidden"src={AzulImg} alt="error"/>
                <p className="otp-veo-air-pcnts otp-veo-hidden">{percents.azul}%</p>
              </div>
              <div className="otp-veo-gol otp-veo-hidden">
                <img className="otp-veo-hidden" src={GolImg} alt="error"/>
                <p className="otp-veo-air-pcnts otp-veo-hidden">{percents.gol}%</p>
              </div>
            </div>
            </>}
          </div>
          <TabOtp 
            value1 = {
              <div>
                <PunctualityVeo dailyPunctualityList={dailyPunctualityListSplit} latamGoal={latamGoal}/>
         
              </div>  
              }
            value2 = {  
            <div>
              <MonthPunctualityVeo dailyPunctualityList={dailyPunctualityListSplit} latamGoal={latamGoal}/>
             
            </div>
          }/>

        </>
        )}
        {/* Loader */ isLoading && (<> <Loader id='otp-loader'/> </> )}
      </div>
    </div>
  );
}

export default Otp;